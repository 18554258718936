import { SystemFolderResponse } from "types/appConnection";
import { get, post, Response,delete_req } from "../makeRequest";

export type addNewConnectionType = {
    connection_name: string;
    connection_type: string;
    connection_permissions: Array<string>;
    connected_email: string;
    connected_email_metadata: {
      auth_token: string;
      refresh_token: string;
    };
  };
export type connectionsType = {
    integration_id: string;
    connected_email: string;
    connection_name: string;
    connection_type: string;
  };

  type deleteConnectionType={
    integration_id:string
  }
type responseType = {
    status: string;
    error: string;
    integration_id: string;
    message:string;
 }

type allConnectionType = {
    status:string,
    connections:Array<connectionsType>
}


export function getAllConnection(): Response<allConnectionType>{
    return get(`/get-app-connections`);
}

export function addConnectionRequest(requestData:addNewConnectionType): Response<responseType>{
    return post(`/create-app-connection`,requestData);
}

export function addConnectionRequestUrl(requestData:addNewConnectionType): Response<responseType>{
    return get(`/get-auth-url-onedrive?connection_name=${requestData}`);
}

export function deleteConnectionRequest(requestData:deleteConnectionType): Response<responseType>{
  return delete_req(`/delete-integration/${requestData?.integration_id}`,requestData);
}

export function getsystemFoldersService(path: string): Response<SystemFolderResponse> {
  return get(`/get-connection-directories?folder_path=${path}`);
}

export function deleteLocalDriveFolder(folder_path:string): Response<responseType>{
  return delete_req(`/delete-localdrive-folder?folder_path=${folder_path}`);
}
