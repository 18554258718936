export const getProjectDetails = `
    query getProjectDetails($project_id: String!) {
        getProjectDetails(project_id: $project_id){
            project{
                id
                topic
                created_at
                created_by{
                    name
                    email
                    user_id
                    chat_role
                }
                metadata
            }
            project_metadata{
                agent
                metadata
                attachments{
                    id
                    name
                    mimetype
                    thumbnail
                    s3_file_id
                    metadata
                    status
                }
            }
        }
    }

`;
