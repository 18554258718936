export const appConnectionColors = {
    dark:{
        AppConnectionBgColor:'#212121',
        appConnectionImportButtonBgColor:'#292929',
        appConnectionImportButtonTextColor:'#ECECEC',
        appConnectionImportButtonBorderColor:'#B4B4B4',
    },
    light:{
        AppConnectionBgColor:'#fff',
        appConnectionImportButtonBgColor:'#FFFFFF',
        appConnectionImportButtonTextColor:'#727272',
        appConnectionImportButtonBorderColor:'#727272',
    }
}