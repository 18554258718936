import { ActionType } from "state/action-types"
import { Dispatch } from "redux";
import { RootState } from "state";
// import { API, graphqlOperation } from "aws-amplify";

import { generateClient, GraphQLQuery } from 'aws-amplify/api';
import { getRefreshToken } from "makeRequest";
const client = generateClient({ 
  authMode: 'userPool', 
  headers: async () => ({
    'refresh_token': await getRefreshToken()
  }) 
});

import { alert, push } from "./customRouter";
import { getMessages } from "graphQL/queries";
import * as userServices from "../../services/user"
import { fetchRunTestQuickLinks, getRunTestUserContextTabs, getQuickLinkTestPayloads, saveTestPayload, saveAndRunTestPayload, getTestHistoryConfig, runTestPayload,
    getTestRunDetails, getResultChatId, getLockAndApprovalStatus, getTestHistoryLogs, getUpdateReference, deletePayload, deleteDetail, getBatchUtteranceActivity,
    createBatchRunScheduler, getBatchRunScheduler, updateBatchRunScheduler, BatchRunSchedulerResponse, getScheduledActivityBatch,
    getBatchDetails} from "services/runTestServices";
import { QuickLinkTestPayload, SchedulerPayload } from "types/runTestTypes";
import { getMessagesResponse } from "types/message";

const limit = 25;

export const fetchQuickLinksPayloadsAction = (page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if(page === 1){
                dispatch(isRunTestContextLoader(true));
            }else{
                dispatch(setCommonLoaderForRunTestTableAction(true));
            }
            const {status, body} = await getQuickLinkTestPayloads(page, limit);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_QUICK_LINK_TEST_PAYLOADS_DATA,
                    payload: page === 1 ? body.payloads : [...getState().runTest.payloads, ...body.payloads || []],
                });
                dispatch(isRunTestContextLoader(false));
                dispatch(setCommonLoaderForRunTestTableAction(false));
                dispatch({
                    type: ActionType.SET_QUICK_LINK_TEST_PAYLOADS_HAS_MORE,
                    payload: body.has_next_page
                });
                return true;
            } else {
                dispatch(alert(body?.error));
                dispatch(isRunTestContextLoader(false));
                dispatch(setCommonLoaderForRunTestTableAction(false));
            }
        } catch (error) {
            console.log(error, "error");
            dispatch(isRunTestContextLoader(false));
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
        dispatch({
            type: ActionType.SET_QUICK_LINK_TEST_PAYLOADS_HAS_MORE,
            payload: false
        });
        return false;
    }
};

export const fetchTestsHistoryAction = (page: number, id: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if(page === 1){
                dispatch({
                    type: ActionType.SET_RUN_TEST_QUICK_LINKS_DATA,
                    payload: [],
                });
            }
            dispatch(setCommonLoaderForRunTestTableAction(true));
            const {status, body} = await getTestHistoryConfig(page, limit, id);

            if (status === 200) {
                dispatch({
                    type: ActionType.SET_RUN_TEST_QUICK_LINKS_DATA,
                    payload: page === 1 ? body.runs : [...getState().runTest.runs, ...body.runs],
                });
                dispatch(setCommonLoaderForRunTestTableAction(false));
                dispatch({
                    type: ActionType.SET_RUN_TEST_QUICK_LINKS_HAS_MORE,
                    payload: body.has_next_page
                })
                dispatch(setCommonLoaderForRunTestTableAction(false));
                return true;
            } else {
                dispatch(alert(body?.error));
                dispatch(setCommonLoaderForRunTestTableAction(false));
            }
        } catch (error) {
            console.log(error, "error");
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
        dispatch({
            type: ActionType.SET_RUN_TEST_QUICK_LINKS_HAS_MORE,
            payload: false
        })
        return false;
    }
};

export const setCommonLoaderForRunTestTableAction = (value: boolean) => {
    return {
        type: ActionType.SET_COMMON_LOADER_FOR_RUN_TEST_TABLE,
        payload: value,
    }
}

export const setConfigNameAction = (configName: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.SET_RUN_TEST_CONFIG_NAME,
            payload: configName,
        });
    }
}

export const fetchRunTestQuickLinksAction = (action: string, payload_id: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try {
            const active_tab_id = getState().runTest.user_context_tabs?.[0]?.id;
            dispatch(isRunTestContextLoader(true));
            dispatch(updateRunTestQuickLinks({categories: getState().runTest.quickLinks, current_tab_id: active_tab_id, is_persist: true}));
            const response = await fetchRunTestQuickLinks(active_tab_id, action, payload_id);
            if (response.status === 200) {
                const categories = response.body.categories;
                const current_tab_id  = response.body.current_tab_id;
                dispatch(updateRunTestQuickLinks({categories, current_tab_id, is_persist: true}));
                dispatch(runTestSelectedTabId(current_tab_id));
                dispatch(isRunTestContextLoader(false));
                dispatch(fetchRunTestSelectedTabLinks(current_tab_id, action, payload_id));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
                dispatch(isRunTestContextLoader(false));
            }
        } catch (err) {
            console.log(err)
            dispatch(isRunTestContextLoader(false));
        }
    }
}

export const fetchRunTestSelectedTabLinks = (id: string, action: string, payload_id: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const IsTabAvailable = state.runTest.user_context_tabs.find(tab => tab.id === id);
            const IsSelectedTab = state.runTest.selected_tab_id === id;

            // here hydrating data for selected tab checkboxes data
            dispatch(updateRunTestQuickLinks({categories: state.runTest.quickLinks, current_tab_id: state.runTest.selected_tab_id, is_persist: false}));

            if(IsTabAvailable != null && !IsSelectedTab && IsTabAvailable?.quickLinks != null && IsTabAvailable?.quickLinks?.length > 0){
                // here switching to a tab that has quickLinks hydrated already
                dispatch(runTestSelectedTabId(id));
                dispatch(updateRunTestQuickLinks({categories: IsTabAvailable.quickLinks, current_tab_id: id, is_persist: false}));
                return;
            }
            else{
                // here fetching quickLinks for a tab that has no quickLinks hydrated yet
                dispatch(isRunTestContextLoader(true));
                dispatch(updateRunTestQuickLinks({categories: [], current_tab_id: id, is_persist: false}));
                dispatch(runTestSelectedTabId(id));
                const response = await fetchRunTestQuickLinks(id, action, payload_id);
                if (response.status === 200) {
                    const quickLinksList = response.body;
                    if(getState().runTest.selected_tab_id == quickLinksList.current_tab_id){
                        dispatch(updateRunTestQuickLinks({...quickLinksList, is_persist: true}));
                    }else{
                        const data = {categories: getState().runTest.quickLinks, current_tab_id: getState().runTest.selected_tab_id, is_persist: true};
                        dispatch(updateRunTestQuickLinks({...quickLinksList, is_persist: true}));
                        dispatch(updateRunTestQuickLinks(data));
                    }
                    dispatch(isRunTestContextLoader(false));
                } else {
                    dispatch(isRunTestContextLoader(false));
                    dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
                }
            }
        } catch (err) {
            console.log(err);
            dispatch(isRunTestContextLoader(false));
        }
    }
}


export const updateRunTestQuickLinks = (quickLinksList:any) => {
    return{
        type: ActionType.UPDATE_RUN_TEST_QUICK_LINKS,
        payload: quickLinksList,
    }
}
export const updateSaveButtonEnabled = (enabled:boolean) => {
    return{
        type: ActionType.UPDATE_SAVE_BUTTON_ENABLED,
        payload: enabled,
    }
}

export const isRunTestContextLoader =(value:boolean) => {
    return {
        type: ActionType.IS_RUN_TEST_CONTEXT_LOADER,
        payload: value,
    }
}

export const runTestSelectedTabId = (id:string|number)=> {
    return{
        type: ActionType.RUN_TEST_SELECTED_TAB_ID,
        payload: id,
    }
}

export const getRunTestUserContextTabsAction = (action: string, payload_id: string) => {
    return async (dispatch: any) => {
        try{
            if(payload_id){
                dispatch(updateBatchDetailsAction(payload_id));
            }
            dispatch(isRunTestContextLoader(true))
            const response = await getRunTestUserContextTabs(action, payload_id);
            if(response.status === 200){
                dispatch(isRunTestContextLoader(false));
                dispatch(updatedRunTestContextLabelAction(response.body.tab_main_heading))
                dispatch({
                    type: ActionType.GET_USER_CONTEXT_TABS,
                    payload: response.body.tabs,
                  });
            }else{
                dispatch(isRunTestContextLoader(false));
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
            return true;
        }catch(err){
            console.error(err)
        }
    }
}

export const updateBatchDetailsAction = (payload_id: string) => {
    return async (dispatch: any) => {
        try {
            const result = await getBatchDetails(payload_id);
            if(result.status === 200 && result.body.payload_details){
                const data = result.body.payload_details;
                dispatch(updateBatchPayloadDetailsAction(data));
                dispatch(setConfigNameAction(data.name));
                dispatch(updateUseCache(data?.user_payload?.use_cache));
                dispatch(updateComputeVarianceAnalysis(data?.user_payload?.compute_variance_analysis));
                dispatch(updateMaxRecursionDepthAction(data?.user_payload?.recursion_depth));
                dispatch(updateMaxRecursionAction(data?.user_payload?.max_recursion));
                if(data?.batch_type === "activity"){
                    dispatch(updateBatchActivityDurationAction(data?.user_payload?.duration));
                }
            } else {
                dispatch(alert((result.body as any).error));
            }
        } catch (error) {
            console.error(error);
            dispatch(alert("Error while updating batch details"));
        }
    }
}

export const updatedRunTestContextLabelAction=(data:string)=>{
    return {
        type: ActionType.GET_UPDATED_RUN_TEST_CONTEXT_LABEL,
        payload: data
    }
}

export const toggleSelectAllQuestions = (isSelected: boolean) => {
    return {
        type: ActionType.TOGGLE_SELECT_ALL_QUESTIONS,
        payload: isSelected,
    };
};

export const saveTestPayloadAction = (payload: any, batch_payload_details: QuickLinkTestPayload, action: string, batch_type: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try{
            const state = getState().runTest.saved_scheduler_payload;
            dispatch(isRunTestSaveLoader(true));
            const response = await saveTestPayload(payload, batch_payload_details, action, batch_type);
            if(response.status === 200){
                if(state?.notify_emails?.length > 0 && state?.schedule_frequency !== '' && state?.schedule_date !== ''){
                    dispatch(createBatchRunSchedulerAction(state, response.body.test_payload.id));
                }
                dispatch(isRunTestSaveLoader(false));
                dispatch(updateBatchPayloadDetailsAction(response.body.test_payload));
                dispatch(push(`/batch-${batch_type==="quicklink"?"configuration":"activity"}?batch_id=${response.body.test_payload.id}`));
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
                dispatch(updateSaveButtonEnabled(true));
                if(batch_type === "quicklink"){
                    dispatch({
                        type: ActionType.UPDATE_PERSIST_USER_CONTEXT_TABS,
                        payload: getState().runTest.user_context_tabs,
                    });
                }
            }else{
                dispatch(isRunTestSaveLoader(false));
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
        }catch(err){
            console.log(err)
            dispatch(isRunTestSaveLoader(false));
        }
    };
};

export const createBatchRunSchedulerAction = (payload: SchedulerPayload, id: string) => {
    return async (dispatch: Dispatch) => {
        try{
            const response = await createBatchRunScheduler(payload, id);
            if(response.status === 200){
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
        }catch(err){
            console.log(err);
        }
    }
}

export const isRunTestSaveLoader = (value: boolean) => {
    return {
        type: ActionType.IS_RUN_TEST_SAVE_LOADER,
        payload: value,
    }
}
export const saveAndRunTestPayloadAction = (payload: any, batch_payload_details: QuickLinkTestPayload, action: string, batch_type: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        try{
            const state = getState().runTest.saved_scheduler_payload;
            const isConfigurationScreen = (window.location.pathname.startsWith('/batch-configuration') || window.location.pathname.startsWith('/batch-activity') || window.location.pathname.startsWith('/create-activity'));
            const response = await saveAndRunTestPayload(payload, batch_payload_details, action, batch_type);
            if(response.status === 200){
                if(isConfigurationScreen){
                    if(state.notify_emails.length > 0 && state.schedule_frequency !== '' && state.schedule_date !== ''){
                        dispatch(createBatchRunSchedulerAction(state, response.body.test_payload.id));
                    }
                }
                dispatch({
                    type: ActionType.UPDATE_RUN_TEST_PAYLOAD,
                    payload: response.body.test_payload,
                })
                dispatch(setConfigNameAction(''));
                dispatch(push(`/history/?batch_id=${response.body.test_payload.id}`));
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
            return true;
        }catch(err){
            console.log(err)
        }
    }
}


export const runTestPayloadAction = (id: string, setIsLoading?: (isLoading: boolean) => void) => {
    return async (dispatch: Dispatch) => {
        try{
            setIsLoading && setIsLoading(true);
            const response = await runTestPayload(id);
            if(response.status === 200){
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
                dispatch(push(`/history?batch_id=${id}`));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
            setIsLoading && setIsLoading(false);
        }catch(err){
            console.log(err);
            setIsLoading && setIsLoading(false);
        }
    }
}

export const getTestRunDetailsAction = (id: string, page: number, parent_id: string) => {
    return async (dispatch: Dispatch) => {
        try{
            if(page === 1){
                if(parent_id === "root"){
                    dispatch({
                        type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS,
                        payload: [],
                    });
                }else{
                    dispatch({
                        type: ActionType.UPDATE_RUN_DETAIL_ITEMS,
                        payload: {
                            id: parent_id,
                            run_detail: [],
                            current_page: page,
                            total_pages: 0,
                            total_records: 0,
                        },
                    });
                }
            }
            dispatch(setCommonLoaderForRunTestTableAction(true));
            const response = await getTestRunDetails(id, page, parent_id !=="root" ? parent_id : "", limit);
            if(response.status === 200){
                if(parent_id == "root"){
                    dispatch({
                        type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS,
                        payload: response.body.run_detail,
                    });
                    dispatch({
                        type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS_HAS_MORE,
                        payload: response.body.has_next_page
                    });
                    dispatch(setCommonLoaderForRunTestTableAction(false));
                }else {
                    dispatch({
                        type: ActionType.UPDATE_RUN_DETAIL_ITEMS,
                        payload: {
                            id: parent_id,
                            run_detail: response.body.run_detail,
                            current_page: page,
                            total_pages: response.body.total_pages,
                            total_records: response.body.total_records,
                        },
                    });
                }
                return {
                    parent_id: parent_id,
                    run_detail: response.body.run_detail,
                    has_next_page: response.body.has_next_page,
                    total_pages: response.body.total_pages,
                    current_page: page,
                    total_records: response.body.total_records,
                };
            }else{
                dispatch(setCommonLoaderForRunTestTableAction(false));
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
                return false;
            }
        }catch(err){
            console.log(err);
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
        dispatch({
            type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS_HAS_MORE,
            payload: false
        });
        return false;
    }
}

export const foundResultChatIdAction = (id: string, setIsLoading: (isLoading: boolean) => void) => {
   return async (dispatch: Dispatch<any>) => {
    try{
        setIsLoading(true);
        const response = await getResultChatId(id);
        if(response.status === 200){
            dispatch(updateComparisonResultsAction(response.body.comparison_results));
            setIsLoading(false);
        }else{
            dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            setIsLoading(false);
        }
    }catch(err){
        console.log(err);
        setIsLoading(false);
    }
   }
}

export const updateComparisonResultsAction = (payload: any) => {
    return {
        type: ActionType.UPDATE_QUICK_LINK_COMPARISON_RESULTS,
        payload: payload,
    }
}

export const compareChatIdAction = (id: string, setIsLoading: (isLoading: boolean) => void) => {
   return async (dispatch: Dispatch) => {
    try{
        setIsLoading(true);
        const response = await getResultChatId(id);
        if(response.status === 200){
            dispatch(updateComparisonResultsAction(response.body.comparison_results));
            setIsLoading(false);
        }else{
            dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            setIsLoading(false);
        }
    }catch(err){
        console.log(err);
        setIsLoading(false);
    }
   }
}

export const updateCurrentThreadMessagesTestAction = (chatThreadId: string|null, page:number) => {
    return async (dispatch: Dispatch<any>,getState:()=> RootState) => {
      try {
        const limit = 5;
        if(page === 1){
            dispatch({
                type: ActionType.SET_RUN_TEST_LATEST_MESSAGES,
                payload:[]
              });
        }
        if(chatThreadId){
          const subPayload = {
            chatId: chatThreadId,
            limit,
            page,
            offset: getState().runTest.latest_messages.length,
            sort: 1,
          };
          const result = await client.graphql<GraphQLQuery<getMessagesResponse>>({
            query: getMessages,
            variables: subPayload
          });

          if(result?.data?.getMessages?.messages?.length === 0 ) return true

          if(result?.data){
              // const response = sample_message
              const response = result.data.getMessages;

              // updating messages of current thread
              dispatch({
                type: ActionType.SET_RUN_TEST_LATEST_MESSAGES,
                payload: page === 1 ? response?.messages||[] : [ ...getState()?.runTest.latest_messages,...response?.messages||[]]
              });
              // setting into current_thread_details
              dispatch({
                type: ActionType.SET_BATCH_CURRENT_CHAT_THREAD,
                payload: response.chat_details
              });
              // updating has more messages of current thread for pagination
              return dispatch({
                type: ActionType.UPDATE_RUN_TEST_HAS_MORE_MESSAGES,
                payload: response.has_next_page
              });
            }
        }
      } catch (error: any) {
        if(error?.name === "NoValidAuthTokens"){ 
          dispatch(alert("Your session has expired. Please log in again to continue."));
        }else{
          console.error("Error while fetching messages",error);
          return dispatch(alert("Error while fetching messages"));
        }
      }
    };
  }

export const updateReferenceThreadMessagesTestAction = (chatThreadId: string|null, page:number) => {
    return async (dispatch: Dispatch<any>,getState:()=> RootState) => {
      try {
        if(page === 1){
            dispatch({
                type: ActionType.SET_RUN_TEST_REFERENCE_MESSAGES,
                payload:[]
              });
        }
        const limit = 5;

        if(chatThreadId){
          const subPayload = {
            chatId: chatThreadId,
            limit,
            page,
            offset: getState().runTest.reference_messages.length,
            sort: 1,
          };
        //   const result = await API.graphql<GraphQLQuery<getMessagesResponse>>(graphqlOperation(getMessages, subPayload));
        //   const result = await API.graphql<GraphQLQuery<getMessagesResponse>>(graphqlOperation(getMessages, subPayload));
          const result = await client.graphql<GraphQLQuery<getMessagesResponse>>({
            query: getMessages,
            variables: subPayload
          });

          if(result?.data?.getMessages?.messages?.length === 0 ) return true

          if(result?.data){
              // const response = sample_message
              const response = result.data.getMessages;

              // updating messages of current thread
              dispatch({
                type: ActionType.SET_RUN_TEST_REFERENCE_MESSAGES,
                payload: page === 1 ? response?.messages||[] : [ ...getState()?.runTest.reference_messages,...response?.messages||[]]
              });
              // setting into current_thread_details
              dispatch({
                type: ActionType.UPDATE_REFERENCE_CHAT_THREAD_DETAILS,
                payload: response.chat_details
              });
              // updating has more messages of current thread for pagination
              return dispatch({
                type: ActionType.UPDATE_RUN_TEST_HAS_MORE_MESSAGES,
                payload: response.has_next_page
              });
            }
        }
      } catch (error: any) {
        if(error?.name === "NoValidAuthTokens"){
          dispatch(alert("Your session has expired. Please log in again to continue."));
        }else{
          console.error("Error while fetching messages",error);
          return dispatch(alert("Error while fetching messages"));
        }
      }
    };
  }

export const fetchLockAndApprovalAction = (payload_id:string,details_id: string, action: string) => {
    return async (dispatch: Dispatch) => {
        try{
            const response = await getLockAndApprovalStatus(payload_id,details_id, action);
            if(response.status === 200){
                if (action === "approve" || action === "disapprove") {
                    dispatch({
                        type: ActionType.UPDATE_CURRENT_APPROVAL_STATUS,
                        payload: response.body.current_approval_status,
                    })
                }else if(action === "lock" || action === "unlock"){
                    dispatch({
                        type: ActionType.UPDATE_CURRENT_LOCK_STATUS,
                        payload: response.body.current_lock_status,
                    })
                }
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
                return true;
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
            return;
        }catch(err){
            console.log(err);
        }
    }
}

export const viewResultHistoryAction = (details_id: string, action: string) => {
    return async (dispatch: Dispatch) => {
        try{
            dispatch({
                type: ActionType.UPDATE_ACTION_NAME_OF_LOCK_AND_APPROVAL,
                payload: action,
            })
            dispatch(setCommonLoaderForRunTestTableAction(true));
            const response = await getTestHistoryLogs(details_id, action);
            if(response.status === 200){
                if(action === "lock"){
                    dispatch({
                        type: ActionType.UPDATE_LOCK_AND_APPROVAL_HISTORY_LOGS,
                        payload: response.body.lock_status,
                    })
                }else if(action === "approve"){
                    dispatch({
                        type: ActionType.UPDATE_LOCK_AND_APPROVAL_HISTORY_LOGS,
                        payload: response.body.approval_status,
                    })
                }
                dispatch(setCommonLoaderForRunTestTableAction(false));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
                dispatch(setCommonLoaderForRunTestTableAction(false));
            }
        }catch(err){
            console.log(err);
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
    }
}

export const setIsSidebarOpenAction = (value: boolean) => {
    return {
        type: ActionType.SET_IS_SIDEBAR_OPEN,
        payload: value,
    }
}

export const updateReferenceButtonDetailsAction = (payload_id: string, component_id: string, current_reference_id: string) => {
   return async (dispatch: Dispatch) => {
    try{
        dispatch(setCommonLoaderForRunTestTableAction(true));
        const response = await getUpdateReference(payload_id, component_id, current_reference_id);
        if(response.status === 200){
            dispatch({
                type: ActionType.UPDATE_REFERENCE_BUTTON_DETAILS,
                payload: response.body,
            })
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }else{
            dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
    }catch(err){
        console.log(err);
        dispatch(setCommonLoaderForRunTestTableAction(false));
    }
   }
}

export const deletePayloadAction = (id: string, setIsLoading: (isLoading: boolean) => void) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try{
            setIsLoading(true);
            const state = getState().runTest.payloads;
            const response = await deletePayload(id);
            if(response.status === 200){
                const newPayloads = state.filter((item) => item.id !== id);
                dispatch({
                    type: ActionType.SET_QUICK_LINK_TEST_PAYLOADS_DATA,
                    payload: newPayloads,
                })
                setIsLoading(false);
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
                setIsLoading(false);
            }
        }catch(err){
            console.log(err);
            setIsLoading(false);
        }
    }
}

export const deleteDetailAction = (id: string, setIsLoading: (isLoading: boolean) => void) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try{
            setIsLoading(true);
            const state = getState().runTest.run_detail;
            const response = await deleteDetail(id);
            if(response.status === 200){
                const newPayloads = state.filter((item) => item.id !== id);
                dispatch({
                    type: ActionType.UPDATE_RUN_TEST_RUN_DETAILS,
                    payload: newPayloads,
                })
                dispatch({
                    type: ActionType.DELETE_RUN_DETAIL_ITEM,
                    payload: id,
                })
                setIsLoading(false);
                setTimeout(() => {
                    dispatch(alert(response?.body?.message, {position: "bottom-left"}, "success"));
                });
            }else{
                setIsLoading(false);
                dispatch(alert(response?.body?.error, {position: "bottom-left"}, "error"));
            }
        }catch(err){
            console.log(err);
            setIsLoading(false);
        }
    }
}

export const saveIssueInfoAction = (issueInfo: string) => {
    return {
        type: ActionType.SAVE_ISSUE_INFO_ACTION,
        payload: issueInfo,
    }
}

export const resetRunTestState = () => {
    return {
        type: ActionType.RESET_RUN_TEST_STATE,
    }
}

export const updateMarkIsAllActivitySelectedAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_MARK_IS_ALL_ACTIVITY_SELECTED,
        payload: value,
    }
}

export const fetchBatchUtteranceActivityAction = (duration: number, page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if (page === 1) {
                dispatch(setCommonLoaderForRunTestTableAction(true));
            }

            const response = await getBatchUtteranceActivity(duration, page, 100);
            if (response.status === 200) {
                dispatch(updateBatchActivityTotalRecordsAction(response.body?.total_records || 0));
                let newActivities = response.body.activity;
                if(getState().runTest.batch_utterance_activity.length > 0) {
                if(getState().runTest.batch_utterance_activity.every(item => !item.is_selected)){
                    newActivities = newActivities.map((item: any) => ({
                        ...item,
                        is_selected: false,
                    }));
                } else if (getState().runTest.batch_utterance_activity.every(item => item.is_selected)) {
                    newActivities = newActivities.map((item: any) => ({
                        ...item,
                        is_selected: true,
                    }));
                    }
                }

                dispatch({
                    type: ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY,
                    payload: page === 1 ? newActivities : [...getState().runTest.batch_utterance_activity, ...newActivities],
                });

                dispatch({
                    type: ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY_HAS_MORE,
                    payload: response.body.has_next_page,
                });
                dispatch(setCommonLoaderForRunTestTableAction(false));
            } else {
                dispatch(alert(response.body.error, { position: "bottom-left" }, "error"));
                dispatch(setCommonLoaderForRunTestTableAction(false));
            }
        } catch (err) {
            console.log(err);
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
    }
}

export const updateBatchActivityUnselectedItemsCopyAction = (unselectedItems: string[]) => {
    return {
        type: ActionType.UPDATE_BATCH_ACTIVITY_UNSELECTED_ITEMS_COPY,
        payload: unselectedItems,
    }
}

export const fetchScheduledActivityBatch = (payload_id:string,duration_value:number,page:number)=>{
    return async (dispatch: any, getState: () => RootState)=>{
        try {
            if(page == 1 ) {
                if(!getState().runTest.batch_payload_details){
                    dispatch({
                        type: ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY,
                        payload: [],
                    })
                }
                if(payload_id && !getState().runTest.batch_payload_details){
                    dispatch(updateBatchDetailsAction(payload_id));
                }
                dispatch(updateBatchActivityUnselectedItemsCopyAction([]));
                dispatch(setCommonLoaderForRunTestTableAction(true));
            }
            const response:any = await getScheduledActivityBatch(payload_id,duration_value,page);
            if(response.status === 200){
                dispatch({
                    type: ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY,
                    payload: page === 1 ? response.body.activity : [...getState().runTest.batch_utterance_activity, ...response.body.activity],
                })

                dispatch({
                    type: ActionType.UPDATE_BATCH_UTTERANCE_ACTIVITY_HAS_MORE,
                    payload: response.body.has_next_page,
                })
                dispatch(updateBatchActivityUnselectedItemsAction(response.body?.payload_info?.exclude_utterances));
                dispatch(
                    updateBatchActivityUnselectedItemsCopyAction([
                      ...getState().runTest?.unselected_utterance_ids_copy,
                      ...response.body?.activity
                        .filter((item: any) => !item.is_selected)
                        .flatMap((item: any) => item.utterance || [])
                    ])
                  );
                dispatch(updateBatchActivityTotalRecordsAction(response.body.total_records));
                dispatch(setCommonLoaderForRunTestTableAction(false));
            } else {
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
                dispatch(setCommonLoaderForRunTestTableAction(false));
            }
        } catch (error) {
            console.log("Error:",error);
            dispatch(setCommonLoaderForRunTestTableAction(false));
        }
    }
}
export const updateUseCache = (value: boolean) => {
    return {
        type: ActionType.UPDATE_USE_CACHE,
        payload: value
    }
}

export const updateComputeVarianceAnalysis = (value: boolean) => {
    return {
        type: ActionType.UPDATE_COMPUTE_VARIANCE_ANALYSIS,
        payload: value
    }
}

export const updateBatchActivityTotalRecordsAction = (total_records: number) => {
    return {
        type: ActionType.UPDATE_BATCH_ACTIVITY_TOTAL_RECORDS,
        payload: total_records,
    }
}

export const updateBatchActivityDurationAction = (duration: number) => {
    return {
        type: ActionType.UPDATE_BATCH_ACTIVITY_DURATION,
        payload: duration,
    }
}

export const updateBatchActivityUnselectedItemsAction = (unselectedItems: string[]) => {
    return {
        type: ActionType.UPDATE_BATCH_ACTIVITY_UNSELECTED_ITEMS,
        payload: unselectedItems,
    }
}

export const showUsersEmailList = () => {
    return async (dispatch : Dispatch) => {
        try {
            const response = await userServices.fetchUsersLists();
            if(response.status == 200){
                dispatch({
                    type: ActionType.GET_USERS_EMAIL_LIST_FOR_SCHEDULER ,
                    payload: response.body.users
                });
            }
        }catch(error){
            dispatch(alert("Failed to fetch Users list!!",  { position: "bottom-left" }));
        }
    }
}

export const saveSchedulerPayloadAction = (payload: SchedulerPayload) => {
    return {
        type: ActionType.SAVE_SCHEDULER_PAYLOAD,
        payload: payload,
    }
}

export const batchRunSchedulerAction = (payload_id: string) => {
    return async (dispatch: Dispatch<any>) => {
        try{
            const response = await getBatchRunScheduler(payload_id);
            const { schedule_payload } = response.body as BatchRunSchedulerResponse;
            if(response.status === 200){
                const payload ={
                    notify_emails: schedule_payload?.notify_emails,
                    schedule_frequency: schedule_payload?.schedule_frequency,
                    schedule_date: schedule_payload?.schedule_date,
                }
                dispatch(saveSchedulerPayloadAction(payload));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
        }catch(err){
            console.log(err);
        }
    }
}

export const updateBatchRunSchedulerPayloadAction = (payload: SchedulerPayload, payload_id: string) => {
    return async (dispatch: Dispatch) => {
        try{
            const response = await updateBatchRunScheduler(payload, payload_id);

            if(response.status === 200){
                dispatch(alert(response.body.message, {position: "bottom-left"}, "success"));
            }else{
                dispatch(alert(response.body.error, {position: "bottom-left"}, "error"));
            }
        }catch(err){
            console.log(err);
        }
    }
}

export const resetSchedulerPayloadAction = ()=>{
    const payload = {
        notify_emails: [],
        schedule_frequency: '',
        schedule_date: ''
    }
    return{
        type: ActionType.SAVE_SCHEDULER_PAYLOAD,
        payload: payload,
    }
}

export const setPage = (page: number) => {
    return {
        type: ActionType.SET_PAGE_FOR_BATCH_ACTIVITY_DURATION,
        payload: page,
    }
}

export const updateMaxRecursionAction = (max_recursion: number) => {
    return {
        type: ActionType.UPDATE_MAX_RECURSION,
        payload: max_recursion,
    }
}

export const updateMaxRecursionDepthAction = (max_recursion_depth: number) => {
    return {
        type: ActionType.UPDATE_MAX_RECURSION_DEPTH,
        payload: max_recursion_depth,
    }
}

export const updateBatchPayloadDetailsAction = (payload: QuickLinkTestPayload | null) => {
    return {
        type: ActionType.UPDATE_BATCH_PAYLOAD_DETAILS,
        payload: payload,
    }
}

export const removeRunDetailItemsAction = () => {
    return {
        type: ActionType.REMOVE_RUN_DETAIL_ITEMS,
    }
}