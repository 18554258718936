import { get, post, Response, delete_req } from "makeRequest";
import { GetSemanticCacheQuestionsData, SemanticCacheRecord } from "types/semanticCacheTypes";

interface GetSemanticCacheDataResponse {
    data: {
        total: number;
        has_next: boolean;
        semantic_cache_records: SemanticCacheRecord[];
    }
    error: string;
    message: string;
}
interface GetSemanticCacheQuestionsResponse {
    data: GetSemanticCacheQuestionsData;
    error?: string;
    message?: string;
    status?: boolean;
}
interface GetSemanticCacheAnswerResponse {
    answer: string | any;
    route_type: string;
    error?: string;
    message?: string;
    status?: boolean;
}

export const getSemanticCacheData = (payload: any): Response<GetSemanticCacheDataResponse> => {
    return post(`/get-semantic-cache-data`, payload);
}

export const getSemanticCacheQuestions = (id: string, page: number, limit: number): Response<GetSemanticCacheQuestionsResponse> => {
    return post(`/get-semantic-cache-questions`, {id, page_number: page, limit});
}

export const getSemanticAgent = (page: number, limit: number): Response<any> => {
    return get(`/get-agents?page=${page}&limit=${limit}`);
}

export const getSemanticFocus = (page: number, limit: number): Response<any> => {
    return get(`/get-focus?page=${page}&limit=${limit}`);
}

export const getSemanticSource = (page: number, limit: number, is_private: boolean): Response<any> => {
    return get(`/get-data-sources?page=${page}&limit=${limit}&is_private=${is_private}`);
}

export const deleteSemanticCache = (id: string): Response<{status: boolean, message?: string, error?: string}> => {
    return delete_req(`/delete-by-semantic-cache-id/${id}`);
}

export const deleteSemanticCacheQuestion = (id: string): Response<{status: boolean, message?: string, error?: string}> => {
    return delete_req(`/delete-by-question-id/${id}`);
}

export const getSemanticCacheAnswer = (id: string): Response<GetSemanticCacheAnswerResponse> => {
    return get(`/get-semantic-cache-answer/${id}`);
}

export const updateUserFeedback = (payload: {feedback: string, question_id?: string|number, answer_id?: string|number}): Response<any> => {
    return post(`/add-reaction-to-answer`, payload);
}