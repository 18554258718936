import { generateClient, GraphQLQuery } from "aws-amplify/api";
import { createProject, removeProject, updateProject } from "graphQL/mutations";
import { getChats, getProjectDetails, getProjects } from "graphQL/queries";
import { getRefreshToken } from "makeRequest";
import { Dispatch } from "redux";
import { ActionType } from "state/action-types";
import { GetProjectsResponse, Project, projectEvent, ProjectMetadata } from "types/project";
import { alert, push } from "./customRouter";
import { Event } from "types/eventReciever";
import { getChatsResponse } from "types/chatv2";
import { parseMetadata } from "./ChatEventActions";
import { setLatestMessageParameters } from "./ChatActions";
import { chatAgents } from "types/chat";


const client = generateClient({
    authMode: 'userPool',
    headers: async () => ({
      'refresh_token': await getRefreshToken()
    })
  });



// Action to update projects list
export const updateProjects = (projects: Project[]) => ({
    type: ActionType.UPDATE_PROJECTS,
    payload: projects,
});

export const updateProjectsHasMore = (has_more_projects: boolean) => ({
  type: ActionType.UPDATE_PROJECTS_HAS_MORE,
  payload: has_more_projects,
});

export const createProjectEventAction = (event: Event) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const projectData = event.content.project.project
      if(getState().chat.projects.find((project: Project) => project.id === projectData?.id)) return true;
      dispatch(updateProjects([projectData, ...getState().chat.projects]));
      dispatch(alert("Project created successfully", { position: "bottom-left" }, "success"));
    } catch (error) {
      dispatch(alert("Something went wrong while creating project"));
      console.error(error);
    }
  }
};

export const deleteProjectEventAction = (event: Event) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      if(getState().chat.projects.find((project: Project) => project.id === event.content?.project?.project?.id)) {
        dispatch(updateProjects(getState().chat.projects.filter((project: any) => project.id !== event.content?.project?.project?.id)));
        dispatch(alert("Project removed successfully", { position: "bottom-left" }, "success"));
        if(new URLSearchParams(window.location.search).get("project_id") === event.content?.project?.project?.id){
          dispatch(push("/"));
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      dispatch(alert("Something went wrong while deleting project"));
    }
  }
};

export const updateProjectEventAction = (event: Event) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      if(getState().chat.projects.find((project: Project) => project.id === event.content?.project?.project?.id)) {
        dispatch(updateProjects(getState().chat.projects.map((project: any) => project.id !== event.content?.project?.project?.id ? project : {
          ...project,
          ...event.content?.project?.project,
          project_metadata: event.content?.project?.project_metadata
        })));
      }
      return true;
    } catch (error) {
      console.error(error);
      dispatch(alert("Something went wrong while updating project"));
    }
  }
};

export const getProjectsAction = (page = 1) => {
  return async (dispatch: Dispatch, getState: () => any) => {
      try {
        const { projects , has_more_projects:has_more_pages } = getState().chat;
        const subPayload = { page, limit: 10 , offset: projects.length };
  
        if(has_more_pages || page == 1){
          const response = await client.graphql<GraphQLQuery<GetProjectsResponse>>({
            query: getProjects,
            variables: subPayload
          });
  
          if(response.data && response.data.getProjects){
            const {
              getProjects: { projects: newProjects, has_next_page },
            } = response.data;
  
            const uniqueProjects = [
              ...projects,
              ...newProjects.filter((newProject:Project) => 
                !projects.some((existingProject:Project) => existingProject.id === newProject.id)
              )
            ];

            dispatch(updateProjects(uniqueProjects));
            dispatch(updateProjectsHasMore(has_next_page));
          } else if (response.errors){
            console.error(response.errors)
            dispatch(alert("Something went wrong while fetching projects"));
          }
        }
        return true;
      } catch (err: any) {
        console.error(err)
        dispatch(updateProjectsHasMore(false));
        if(err?.name === "NoValidAuthTokens"){
          dispatch(alert("Your session has expired. Please log in again to continue."));
        }else{
          dispatch(alert("Something went wrong while fetching projects"));
        }
      }
  }
}

export const createProjectAction = (payload: { topic: string, metadata: string }) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const response = await client.graphql<GraphQLQuery<{ createProject: Event }>>({
        query: createProject,
        variables: payload
      });
      if(response.data && response.data.createProject){
        const projectData = response.data.createProject?.content?.project?.project
        if(getState().chat.projects.find((project: Project) => project.id === projectData?.id)) return true;
        dispatch(updateProjects([projectData, ...getState().chat.projects]));
        dispatch(push(`/project?project_id=${projectData?.id}`));
        dispatch(alert("Project created successfully", { position: "bottom-left" }, "success"));
      } else if (response.errors){
        console.error(response.errors)
        dispatch(alert("Something went wrong while creating project"));
      }
      return true;
    } catch (error: any) {
      console.error(error);
      dispatch(alert("Something went wrong while creating project"));
      return false;
    }
  }
}

export const removeProjectAction = (project_id: string) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const response = await client.graphql<GraphQLQuery<{ removeProject: Event }>>({
        query: removeProject,
        variables: { project_id }
      });
      if(response.data && response.data.removeProject){
        dispatch(updateProjects(getState().chat.projects.filter((project: any) => project.id !== project_id)));
        dispatch(alert("Project removed successfully", { position: "bottom-left" }, "success"));
      } else if (response.errors){
        console.error(response.errors)
        dispatch(alert("Something went wrong while removing project"));
      }
      return true;
    } catch (error: any) {
      console.error(error);
      dispatch(alert("Something went wrong while removing project"));
      return false;
    }
  }
}

export const updateProjectAction = (project_id: string, updateProjectMetadata: ProjectMetadata) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    try {
      const response = await client.graphql<GraphQLQuery<{ updateProject: Event }>>({
        query: updateProject,
        variables: { project_id, metadata: JSON.stringify(updateProjectMetadata || {}) }
      });
      if(response.data && response.data.updateProject){
        dispatch(updateProjects(getState().chat.projects.map((project: any) => project.id !== project_id ? project : {
          ...project,
          ...response.data.updateProject.content?.project?.project,
          project_metadata: response.data.updateProject.content?.project?.project_metadata
        })));
      } else if (response.errors){
        console.error(response.errors)
        dispatch(alert("Something went wrong while updating project"));
      }
      return true;
    } catch (error: any) {
      console.error(error);
      dispatch(alert("Something went wrong while updating project"));
      return false;
    }
  }
}

export const fetchProjectDetails = (project_id: string) => {
  return async (dispatch: any, getState: () => any) => {
    const agent = getState().agent?.agents?.find((agent: chatAgents) => agent?.is_default_agent);
    try {
      const response = await client.graphql<GraphQLQuery<{ getProjectDetails: projectEvent }>>({
        query: getProjectDetails,
        variables: { project_id }
      });
      if(response.data && response.data.getProjectDetails){
        const project_metadata = response.data.getProjectDetails.project_metadata;
        const updatedProject = {
            ...response.data.getProjectDetails.project,
            project_metadata: project_metadata  ,
        }
        if(!project_metadata?.agent){
          dispatch({type: ActionType.SET_ACTIVE_AGENT,
            payload: agent,});
        }
        dispatch(updateProjects([
          ...(!getState().chat.projects.find((project: any) => project.id === project_id)) 
            ? [updatedProject] 
            : [],
          ...getState().chat.projects.map((project: any) => project.id !== project_id ? project : {
            ...(project || {}),
            ...updatedProject
          })
        ]));
        const project_focus = parseMetadata(response.data.getProjectDetails.project?.metadata)?.focus;
        const project_sources = parseMetadata(response.data.getProjectDetails.project?.metadata)?.sources;
        const messages = project_focus ? [{focus:project_focus,sources:project_sources&&project_sources?.length?project_sources:[]}] : [];
        dispatch(setLatestMessageParameters(project_metadata?.agent || "",(messages as [])))
        return true;
      } else if (response.errors){
        console.error(response.errors)
        dispatch(alert("Something went wrong while fetching project details"));
      }
    } catch (error: any) {
      console.error(error);
      dispatch(alert("Something went wrong while fetching project details"));
    }
    return false;
  }
}

// Action to fetch chats list
export const getProjectsChatThreadsAction = (page = 1) => {
  return async (dispatch: Dispatch, getState: () => any) => {
    const project_id = new URLSearchParams(window.location.search).get("project_id");
    try {
      const project = getState().chat.projects.find((project: any) => project.id === project_id);
      const { threads = [], has_next_page:has_more_pages = false } = project || {};
      if(!project_id){
        dispatch(alert("Project not found"));
        return false;
      }
      const subPayload = { page, limit:10 , offset: threads?.length || 0 , project_id };

      if(has_more_pages || page == 1){
        const response = await client.graphql<GraphQLQuery<getChatsResponse>>({
          query: getChats,
          variables: subPayload
        });

        if(response.data){
          const {
            getChats: { chats: newChats, has_next_page },
          } = response.data;

          if (newChats) {
            const appendedChats = page === 1 ? [...newChats] : [...threads, ...newChats];
            dispatch(updateProjects(getState().chat.projects.map((project: any) => project.id !== project_id ? project : {
              ...project,
              threads: appendedChats,
              page: page,
              has_next_page: has_next_page
            })));
          }
        }
      }
      return true;
    } catch (err: any) {
      console.error(err)
      dispatch(updateProjects(getState().chat.projects.map((project: any) => project.id !== project_id ? project : {
        ...project,
        has_next_page: false
      })));
      if(err?.name === "NoValidAuthTokens"){
        dispatch(alert("Your session has expired. Please log in again to continue."));
      }else{
        dispatch(alert("Something went wrong while fetching chat threads"));
      }
    }
  };
};
export const updateProjectDataAction = (Project: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const project = getState().chat.projects.find((project: any) => project.id === Project.id);
    const updatedAttachments = project.project_metadata?.attachments?.map((attachment: any) =>
      attachment.id === Project.project_metadata?.attachments?.id ? Project.project_metadata?.attachments : attachment
    );
    const fileExists = updatedAttachments.some(
      (file: any) => (file?.id === Project.project_metadata?.attachments?.id)
    );
    if (!fileExists) {
      updatedAttachments.push(Project.project_metadata?.attachments);
    }
    const uniqueAttachments = Array.from(new Set(updatedAttachments.map((attachment: any) => attachment.id)))
      .map(id => updatedAttachments.find((attachment: any) => attachment.id === id));
  
   
    dispatch(updateProjects(getState().chat.projects.map((project: any) => project.id !== Project.id ? project : {
      ...project,
      project_metadata: {
        ...project.project_metadata,
        attachments:uniqueAttachments
      }
    })));
    if(uniqueAttachments.some((data:any)=>data.status !=='uploading')){
      dispatch(updateProjectAction(project?.id, {
        ...project?.project_metadata||{},
        agent: project?.project_metadata?.agent || "",
        metadata: project?.project_metadata?.metadata || "",
        attachments:[Project?.project_metadata?.attachments]
      }))}

}}
// action to delete file from project popup
export const deleteFileFromProjectAction = (fileId: string, projectId: string) => {
  return async (dispatch: any, getState: () => any) => {
    dispatch(setIsProjectFilesDeleting(true));
    try {
      const payload = {
        project_id: projectId,
        type: 'remove',
        metadata: JSON.stringify({ attachments: [fileId] })
      };

      const response = await client.graphql<GraphQLQuery<{ updateProject: Event }>>({
        query: updateProject,
        variables: payload
      });

      if (response.data && response.data.updateProject) {
        dispatch(updateProjects(getState().chat.projects.map((project: any) => project.id !== projectId ? project : {
          ...project,
          ...response.data.updateProject.content?.project?.project,
          project_metadata: response.data.updateProject.content?.project?.project_metadata
        })));
        dispatch(alert("File removed successfully", { position: "bottom-left" }, "success"));
        dispatch(setIsProjectFilesDeleting(false));
      } else if (response.errors) {
        console.error(response.errors);
        dispatch(alert("Something went wrong while removing file"));
        dispatch(setIsProjectFilesDeleting(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(alert("Something went wrong while removing file"));
      dispatch(setIsProjectFilesDeleting(false));
    }
  }
}

export const setIsProjectFilesDeleting = (isProjectFilesDeleting: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.SET_IS_PROJECT_FILES_DELETING, payload: isProjectFilesDeleting });
  }
} 