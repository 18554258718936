import { chatThread } from "./chatv2";
import { projectEvent } from "./project";

export type Author = {
    role: Role;
    tool: string;
    metadata: string;
};
export type Role = 
    "system_manager"
    |"analysis_assistant"
    |"streaming_assistant"
    |"visual_assistant"
    |"progress_assistant"
    |"context_assistant";

export enum MessageAuthorRoles {
    SYSTEM_MANAGER="system_manager",
    ANALYSIS_ASSISTANT="analysis_assistant",
    STREAMING_ASSISTANT="streaming_assistant",
    VISUAL_ASSISTANT="visual_assistant",
    PROGRESS_ASSISTANT="progress_assistant",
    CONTEXT_ASSISTANT="context_assistant",
}

export type MessageContent = {
    type: string;
    message: string;
    metadata: string;
    project: projectEvent;
}

export type UserMessage = {
    author: Author;
    content: MessageContent;
    metadata: string;
};

export type Sender = {
    user_id: string;
    name: string;
    email: string;
    chat_role: string;
};

export type DeltaType = 
    "none"|
    "visual"|
	"add"|
    "remove"|
    "replace"|
    "status"|
    "debug"|
    "blocking"|
    "error"|
    "file"|
    "code"|
    "step_status"|
    "context"|DebugLogTypes;
    

export type processingStates = "initializing"|"processing"|"failure"|"retry"|"done"|"blocked";

export type ProcessingStatus = {
    state: processingStates;
    reason: string;
};

export type step_status = {
    stepStatus: string;
    stepNumber: number;
    description: string;
    totalSteps: number;
    title: string;
}
export type DebugLog = {
    debug_message: string;
    debug_type: DebugLogTypes;
    debug_time: number;
}
export type DebugLogTypes = "log_info"|"log_debug"|"log_warning"|"log_error";
export const debugLogTypes:DeltaType[] = [ "log_info", "log_debug","log_warning","log_error" ];

export enum debug_type {
    LOG_INFO="log_info",
    LOG_DEBUG="log_debug",
    LOG_WARNING="log_warning",
    LOG_ERROR="log_error",
}

export type Delta = {
    delta_id: string;
    created_at: number;
    updated_at: string;
    delta_type: DeltaType;
    delta_data_type: string;
    delta: string;
    debug_logs?: DebugLog[]
};

export type Fragment = {
    id: string;
    created_at: number;
    author: Author;
    recepient: string;
    content: string;
    answer_id: string;
    sub_message_id: string;
    readout_title: string;
    processing_status: ProcessingStatus;
    deltas: Delta[];
};

export type ResponseComponent = {
    id: string;
    answer_version_number: number;
    processing_status: ProcessingStatus;
    feedback: {
        text: string | null;
        reaction: string | null;
    };
    metadata: string;
    fragments: Fragment[];
    loading?: boolean;
};

export type MessageVersion = {
    id: string;
    created_at: number;
    message_intent: "question"|"private";
    question_version_number: number;
    user_message: UserMessage;
    sender: Sender;
    response?: Response; // use to hydrate response for question versions
};

export type Response = {
    id: string;
    parent_message_details: MessageVersion;
    response_versions: string[];
    response_component: ResponseComponent;
    answers?: ResponseComponent[];
    compare_response_component?: ResponseComponent;
};

export type getMessagesResponse = {
    getMessages:{
        chat_details: chatThread
        messages:Message[]
        has_next_page:boolean
    }
}

export type Message = {
    created_at: number;
    focus: string;
    sources: string[];
    message_versions: MessageVersion[];
    id: string;
    sender: Sender;
    response: Response;
    showLogs?: boolean;
    is_compare?: boolean;
};
