import { get, Response } from "makeRequest";
import { AdvancedOptionMenu } from "types/chat";


interface AdvancedOptionMenuResponse {
    message?: string;
    error?: string;
    body?: AdvancedOptionMenu;
}


export function getAdvancedOptionMenu(focus: string, chatId?: string, messageId?: string, project_id: string|null = null): Response<AdvancedOptionMenuResponse> {
    const chat_id_params = chatId ? `&chat_id=${chatId}` : '';
    const message_id_params = messageId ? `&message_id=${messageId}` : '';
    const project_id_params = project_id ? `&project_id=${project_id}` : '';
    return get(`/get-advanced-menu-details?focus=${focus}${chat_id_params}${message_id_params}${project_id_params}`);
}
