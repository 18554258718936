import { Colors } from "style/color";

export const newTopHeaderColors = {
    dark:{
        topHeaderBackground: "#212121",
        TopHeaderBackBtnColor:"#FFFFFF",
        AgentBorder:"#404040",
        AgentTextColor: Colors.AMOUR_WHITE,
        AgentBackground:"#000000",
        workerBorder: "#404040",
        AgentTypeTextColor:"#fff",
        AgentTypeKeyTextColor: Colors.DEBUG_BTN_COLOR,
        WorkflowFormLabelColor: "#ECECEC",
        InputBgColor: Colors.AGENTS_BORDER_COLOR,
        WorkflowFormBgColor: '#292929',
        WorkflowFormBorderColor: '#3F3F3F',
        WorkflowFormHeadingColor: '#ECECEC',
        InputBorderColor: "#FFFFFF4D",
        InputTextColor: "#B4B4B4",
        tableBorderColor: '#D9D9D94D',
        tableHeaderColor: '#29292912',
        tableTdColor: '#B4B4B4',
        HeadingTextColor: '#ECECEC',
        ArrowTopHeaderColor: "#3A3A3A",
        onHoverArrowTopHeaderColor: "#ECECEC",
        BackArrowColor: "#B4B4B4",
},
    light:{
        HeadingTextColor: '#2C2C2C',
        topHeaderBackground: "#FFFFFF",
        TopHeaderBackBtnColor:"#515C79",
        AgentBorder:"#D9D9D9",
        AgentTextColor: Colors.OPTION_LIST_TEXT_COLOR,
        AgentBackground:"#FFFFFF",
        workerBorder: "#DDDDDD",
        AgentTypeTextColor:"#2C2C2C",
        AgentTypeKeyTextColor: Colors.GREY_WHITE,
        WorkflowFormLabelColor: "#787878",
        InputBgColor: Colors.WHITE,
        WorkflowFormBgColor: '#EEEFF3',
        WorkflowFormBorderColor: '#D9D9D9',
        WorkflowFormHeadingColor: '#2C2C2C',
        InputBorderColor: "#0000001A",
        InputTextColor: "#2C2C2C",
        tableBorderColor: '#dee2e6',
        tableHeaderColor:'#EFF3FD',
        tableTdColor: '#787878',
        ArrowTopHeaderColor: "#E3E7F0",
        onHoverArrowTopHeaderColor: "#2C2C2C",
        BackArrowColor: "#727272",
    },
}