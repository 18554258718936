export const getChats = `
  query getChats($page: Int, $limit: Int, $offset: Int, $project_id: String) {
    getChats(page: $page, limit: $limit, offset: $offset, project_id: $project_id) {
      chats {
        created_at
        id
        agent
        users {
          user_id
          email,
          name
          chat_role
        }
        topic
        access_scope
        is_pinned_chat
        is_closed_chat
        is_chat_processed
        metadata
      }
      page
      has_next_page
    }
  }
`;