import { get, patch, post, Response, delete_req, put } from "makeRequest";
import { BatchRunSchedulePayload, BatchUtteranceActivity, ComparisonResultsResponse, LogsResponse, QuickLinkTestPayload, ReferenceDetailsResponse, RunPayload, RunTestQuickLinks, SchedulerPayload, TabsResponse, TestRunDetail } from "types/runTestTypes";

interface QuickLinkTestPayloadResponse {
  payloads: QuickLinkTestPayload[];
  has_next_page?: boolean;
  total_pages?: number;
  message?: string;
  status?: number;
  error?: string;
}

export interface SubmitTestPayloadResponse {
  status: string
  test_payload: QuickLinkTestPayload
  run_payload: RunPayload
  message?: string
  error?: string
}

interface TestRunDetailsResponse {
  run_id: string;
  run_detail: TestRunDetail[];
  has_next_page?: boolean;
  message?: string;
  status?: number;
  error?: string;
  total_pages?: number;
  total_records?: number;
}
interface BatchUtteranceActivityResponse {
  activity: BatchUtteranceActivity[];
  has_next_page?: boolean;
  message?: string;
  status?: number;
  error?: string;
  total_records?: number;
}

export interface BatchRunSchedulerResponse {
  schedule_payload: BatchRunSchedulePayload;
  error?: string;
  message?: string;
  status?: number;
}

export const getBatchDetails = (payload_id: string): Response<{payload_details:QuickLinkTestPayload}> => {
 return get(`/batch-payload-details?payload_id=${payload_id}`)
}
export const getQuickLinkTestPayloads = (page: number, limit: number): Response<QuickLinkTestPayloadResponse> => {
 return get(`/fetch-batch-payloads?page=${page}&limit=${limit}`)
}
export const getTestHistoryConfig = (page: number, limit: number, id: string): Response<any> => {
 return get(`/fetch-batch-runs?page=${page}&limit=${limit}&payload_id=${id}`)
}

export function fetchRunTestQuickLinks(tab_id: string, action: string, payload_id: string): Response<RunTestQuickLinks> {
  return get(`/quicklinks-categories-payload?tab_id=${tab_id}&action=${action}&payload_id=${payload_id}`);
}

export function getRunTestUserContextTabs(action: string, payload_id: string): Response<TabsResponse> {
 return get(`/quicklinks-tabs-payload?action=${action}&payload_id=${payload_id}`);
}

export function saveTestPayload(payload: any, batch_payload_details: QuickLinkTestPayload, action: string, batch_type: string,): Response<SubmitTestPayloadResponse> {
  let requestPayload;
  const exclude_utterances = {exclude_utterances: payload, duration: batch_payload_details.user_payload.duration, use_cache: batch_payload_details.user_payload.use_cache, max_recursion: batch_payload_details.user_payload.max_recursion, recursion_depth: batch_payload_details.user_payload.recursion_depth};

  if (batch_type === "activity") {
    requestPayload = {
      batch_type,
      payload: exclude_utterances,
      config_name: batch_payload_details.name,
      action
    };
  } else {
    requestPayload = {
      batch_type,
      payload: {
        use_cache: batch_payload_details.user_payload.use_cache,
        compute_variance_analysis: batch_payload_details.user_payload.use_cache == true ? false : batch_payload_details.user_payload.compute_variance_analysis,
        quicklink_selections: payload,
        max_recursion: batch_payload_details.user_payload.max_recursion,
        recursion_depth: batch_payload_details.user_payload.recursion_depth
      },
      config_name: batch_payload_details.name,
      action
    };
  }

  return post(`/save-batch-payload`, requestPayload);
}

export function saveAndRunTestPayload(payload: any, batch_payload_details: QuickLinkTestPayload, action: string, batch_type: string): Response<SubmitTestPayloadResponse> {
  let requestPayload;
  const exclude_utterances = {exclude_utterances: payload, duration: batch_payload_details.user_payload.duration, use_cache: batch_payload_details.user_payload.use_cache, max_recursion: batch_payload_details.user_payload.max_recursion, recursion_depth: batch_payload_details.user_payload.recursion_depth};

  if (batch_type === "activity") {
    requestPayload = {
      batch_type,
      payload: exclude_utterances,
      config_name: batch_payload_details.name,
      action
    };
  } else {
    requestPayload = {
      batch_type,
      payload: {
        use_cache: batch_payload_details.user_payload.use_cache,
        compute_variance_analysis: batch_payload_details.user_payload.use_cache == true ? false : batch_payload_details.user_payload.compute_variance_analysis,
        quicklink_selections: payload,
        max_recursion: batch_payload_details.user_payload.max_recursion,
        recursion_depth: batch_payload_details.user_payload.recursion_depth
      },
      config_name: batch_payload_details.name,
      action
    };
  }
  return post(`/submit-batch-payload`, requestPayload);
}

export function runTestPayload(id: string): Response<any> {
  return post(`/run-batch-payload?payload`, {payload_id: id});
}

export function getTestRunDetails(id: string, page: number, parent_id: string, limit: number): Response<TestRunDetailsResponse> {
  const parentIdParam = parent_id ? `&parent_id=${parent_id}` : '';
  return get(`/fetch-batch-run-details?run_id=${id}&page=${page}&limit=${limit}${parentIdParam}`);
}

export function getResultChatId(id: string): Response<ComparisonResultsResponse> {
  return get(`/batch-comparison-results?details_id=${id}`);
}

export function getLockAndApprovalStatus(payload_id:string,details_id: string, action: string): Response<{message: string, current_approval_status: string, current_lock_status: string, error: string}> {
  const body = {
    payload_id: payload_id,
    details_id: details_id,
    action: action
  }
  return patch(`/batch-result-update`, body);
}

export function getTestHistoryLogs(details_id: string, action: string): Response<LogsResponse>{
  return get(`/batch-result-action-log?details_id=${details_id}&action=${action}`);
}

export function getUpdateReference(payload_id: string, component_id: string, current_reference_id: string): Response<ReferenceDetailsResponse> {
  return get(`/batch-reference-details?payload_id=${payload_id}&component_id=${component_id}&current_reference_id=${current_reference_id}`);
}

export function deletePayload(id: string): Response<{message: string, error: string}> {
  return delete_req(`/delete-payload?payload_id=${id}`);
}

export function deleteDetail(id: string): Response<{message: string, error: string}> {
  return delete_req(`/delete-result-detail?test_details_id=${id}`);
}

export function getBatchUtteranceActivity(duration: number, page: number, limit: number): Response<BatchUtteranceActivityResponse> {
  return get(`/fetch-utterance-activity?duration=${duration}&page=${page}&limit=${limit}`);
}

export function getScheduledActivityBatch(payload_id:string ,duration_value: number,page: number): Response<BatchUtteranceActivityResponse> {
  return get(`/fetch-utterance-activity-payload?payload_id=${payload_id}&duration=${duration_value}&page=${page}&limit=100`);
}

export function createBatchRunScheduler(payload: SchedulerPayload, id: string): Response<any> {
  const requestPayload = {
    ...payload,
    payload_id: id
  }
  return post(`/create-batch-run-schedule`, requestPayload);
}

export function getBatchRunScheduler(payload_id: string): Response<BatchRunSchedulerResponse> {
  return get(`/batch-run-schedule?payload_id=${payload_id}`);
}

export function updateBatchRunScheduler(payload: SchedulerPayload, id: string): Response<any> {
  const requestPayload = {
    ...payload,
    payload_id: id
  }
  return put(`/update-batch-run-schedule`, requestPayload);
}
