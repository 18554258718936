import { ActionType } from "../action-types";
import { chatAgents, files, selectedFiles } from "types/chat";
import { Command } from "types/chat";


interface agentState {
  // agesnts have all the agents details
    agents: chatAgents[];
    // selected_agent is the agent which is selected by the user
    // this will also be used to get the commands and data sources
    selected_agent?: chatAgents;

  //  selectedCommand is basically focus which is selected by the user
  // use can only selecte 1 focus at a time
  // fased on focus the data sources will be displayed
    selectedCommand: Command | null | undefined; //focus
    // this will be all sources which will be displayed in the carousel for selected focus
    sources: Command[];
    files:files[]
    uploadTasks:string
    selectedFiles:selectedFiles[]
    loadFilesDropdown:boolean,
    // selectedSources will be the sources which are selected by the user
    // adding and removing sources will happen on this array
    selected_sources: string[];
    isShowQueryListCollapse: boolean;
    focus: string;
    has_next_page: boolean;
    page: number;
    isExpandedFocusModalOpen: string;
    loading_menu: boolean;
    advanced_metadata: {
      attachments?: any[];
      description?: string;
      model?: string;
      allow_cache?: boolean;
      project_name?: string;
      prompt_name?: string;
    };
    metadataByFocus: Record<string, any>;
}

export const initialState: agentState = {
  agents: [],
  selected_agent: undefined,
  selectedCommand: null,
  sources: [],
  files:[],
  uploadTasks:"",
  loadFilesDropdown:false,
  selectedFiles:[],
  selected_sources: [],
  isShowQueryListCollapse:false,
  focus: "",
  has_next_page: false,
  page: 1,
  isExpandedFocusModalOpen: "",
  loading_menu: false,
  advanced_metadata: {},
  metadataByFocus: {},
};

const reducer = (state: agentState = initialState, action: any): agentState => {
  switch (action.type) {

    case ActionType.SET_CHAT_CONFIG:
      // Create a map of existing agents, with newer versions overwriting older ones
      const updatedAgents = [...state.agents, ...action.payload].reduce((acc: chatAgents[], agent: chatAgents) => {
        const existingIndex = acc.findIndex((a) => a?.agent_name === agent?.agent_name);
        if (existingIndex >= 0) {
          acc[existingIndex] = agent; // Replace existing agent with new one
        } else {
          acc.push(agent); // Add new agent
        }
        return acc;
      }, []);
      
      return {
        ...state,
        agents: updatedAgents,
      };
    case ActionType.REFETCH_CHAT_CONFIG:
      return {
        ...state,
        agents: action.payload,
      };
    case ActionType.SET_SELECTED_FILES:
      return {
        ...state,
        selectedFiles: action.payload,
      };
    case ActionType.EMPTY_SELECTED_FILE:
      return {
        ...state,
        selectedFiles: state.selectedFiles.filter((file:any)=>file?.chat_id !== action.payload),
        files:state.files.filter((file:any)=>file?.chat_id !== action.payload),
      };

    case ActionType.SET_ACTIVE_AGENT:
      const default_focus = action.payload?.config?.commands?.find((command: Command) => command.is_default_focus);
      const default_selected_sources = default_focus?.default_sources || [];
      const working_default_sources = default_selected_sources.filter((source: string) => default_focus?.works_with?.includes(source));
      const selected_agent: chatAgents = action.payload;
      const {dataSources } = selected_agent?.config || {};
      // selected_agent


      const sourceCommands: Command[] | undefined =    (default_focus?.works_with?.map((source_id: string) => {
        return dataSources?.find((source: Command) => source.id === source_id) || null
      }).filter((item: any) => item) as Command[])

      return {
        ...state,
        selected_agent: action.payload,
        selectedCommand: default_focus,
        sources: sourceCommands || [],
        selected_sources: working_default_sources
        // selectedCommand: action.payload?.config?.commands?.[0] || null,
      };

    case "RESET_AGENT":
      const default_agent = state.agents.find((agent: chatAgents) => agent?.is_default_agent)||state.agents?.[0];
      const default_command = default_agent?.config?.commands?.find((command: Command) => command.is_default_focus)||null;

      const sources_list: Command[] | undefined = default_command
      ? (default_command?.works_with?.map((source_id: string) => {
          return default_agent.config?.dataSources?.find((source: Command) => source.id === source_id) || null
        }).filter((item: any) => item) as Command[])
      : default_agent?.config?.dataSources;

      const default_sources_list = default_command
      ? default_command?.default_sources?.filter((source: string) => default_command?.works_with?.includes(source))||[]
      : [];

      return {
        ...state,
        selected_agent: default_agent,
        selectedCommand: default_command,
        sources: sources_list || [],
        selected_sources: default_sources_list
      };

    case 'RESET_FOCUS':
      return {
        ...state,
        selectedCommand: null,
        sources: state.selected_agent?.config?.dataSources || [],
        selected_sources: [],
      };
 
    case 'RESET_SOURCES':
      return {
        ...state,
        selected_sources: [],
      };

    case "SET_SELECTED_SOURCES":
      return {
        ...state,
        selected_sources: action.payload,
      };

    case "REMOVE_SELECTED_SOURCE":
      return {
        ...state,
        selected_sources: state.selected_sources.filter((source) => source !== action.payload),
      };

    case 'ADD_SELECTED_SOURCE':
      return {
        ...state,
        selected_sources: [...state.selected_sources, action.payload],
      };

    case ActionType.SET_SHOW_QUERY_LIST_COLLAPSE:
      return {
        ...state,
        isShowQueryListCollapse: action.payload,
      };
    case ActionType.SET_UPLOAD_TASK:
      return {
        ...state,
        uploadTasks: action.payload,
      };
    case ActionType.SET_FILES:
      return {
        ...state,
        files: [...state.files, ...action.payload],
      };

    case ActionType.EDIT_FILES_DATA:
      return {
        ...state,
        files:action.payload,
      };

    case ActionType.SET_SELECTED_COMMAND:
      const default_sources = action.payload?.default_sources || [];
      const filter_working_sources = default_sources.filter((source: string) => action?.payload?.works_with?.includes(source));
      const sourceCommandsData = action.payload?.works_with && action.payload?.works_with?.length > 0
                ? action.payload?.works_with.map((source_id: string) => {
                    return state.selected_agent?.config?.dataSources?.find((source: Command) => source.id === source_id) || null
                  }).filter((item: any) => item) as Command[]
                : state.selected_agent?.config?.dataSources;
      const newFocusId = action.payload?.id || 'default';
      const newMetadata = state.metadataByFocus[newFocusId] || {};
      return {
        ...state,
        selectedCommand: action.payload,
        selected_sources: filter_working_sources,
        sources: sourceCommandsData||[],
        advanced_metadata: newMetadata,
      };

    case ActionType.SET_CHAT_INPUT_SOURCES:
      return {
        ...state,
        sources: action.payload,
      };
    case ActionType.EMPTY_FILES:
      return {
        ...state,
        files:state.files.filter((file:any)=>file?.chat_id !== action.payload),
     }
    case ActionType.SET_HAS_NEXT_PAGE:
      return{
        ...state,
        has_next_page:action.payload
      }
    case ActionType.SET_PAGE_NUMBER:
      return{
        ...state,
        page:action.payload
      }
    case ActionType.REMOVE_FILES:
      return{
        ...state,
        files:state.files.filter((file:any)=>file?.fileName !== action.payload),
        selectedFiles:state.selectedFiles.filter((file:any)=>file?.name !== action.payload),
      }
    case ActionType.SET_LOAD_FILES_DROPDOWN:
      return{
        ...state,
        loadFilesDropdown:action.payload
      }
    case ActionType.SET_IS_EXPANDED_FOCUS_MODAL_OPEN:
      return{
        ...state,
        isExpandedFocusModalOpen:action.payload
      }

    case ActionType.ADVANCED_METADATA:
    const currentFocus = state.selectedCommand?.id || 'default';
    return {
      ...state,
      metadataByFocus: {
        ...state.metadataByFocus,
        [currentFocus]: {
          ...state.metadataByFocus[currentFocus],
          ...Object.fromEntries(
            Object.entries(action.payload).filter(([, value]) => value !== undefined && value !== null && value !== "")
          )
        }
      },
      advanced_metadata: {
        ...state.advanced_metadata,
        ...Object.fromEntries(
          Object.entries(action.payload).filter(([, value]) => value !== undefined && value !== null && value !== "")
        )
      }
    };

    case ActionType.RESET_ADVANCED_OPTION_METADATA:
      return {
        ...state,
        metadataByFocus: {},
        advanced_metadata: {}
      }
    case ActionType.UPDATE_LOADING_ADVANCED_MENU:
      return {...state, loading_menu: action.payload}
    default:
      return state;
  }
};

export default reducer;
