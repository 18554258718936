import { ActionType } from '../action-types';
import { Action } from '../actions';
import * as userServices from "../../services/user"
import * as siteServices from "../../services/settings"
import { Dispatch  } from "redux";
import { alert } from './customRouter';
import { RootState } from "state";
import { UserListFromGroup } from "state";
import { newSettings } from 'types/user';
import { refetchChatConfiguration } from './ChatConfigurationAction';
import { updatePortalType } from './CommonActions';

export const updateSetting = ( type: string , updated_data: string) => {
    return async (dispatch: Dispatch<Action> , getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const data = {[type]: updated_data ,action: `${type}`}
            const res:any = await siteServices.updateSiteSettings(id, data)
                if(res.status == 200) {

                    if(type == "logo") {
                        dispatch({
                            type: ActionType.UPDATE_LOGO_COMPLETED,
                            payload: {
                                logo: updated_data
                            }
                        });

                    }
                    else if(type == "light") {
                        dispatch({
                            type: ActionType.UPDATE_LIGHT_LOGO_COMPLETED,
                            payload: {
                                light: updated_data
                            }
                        });
                    }
                    else if(type == "dark") {
                        dispatch({
                            type: ActionType.UPDATE_DARK_LOGO_COMPLETED,
                            payload: {
                                dark: updated_data
                            }
                        });
                    } else if(type == "logoAlt") {
                        dispatch({
                            type: ActionType.UPDATE_LOGOALT_COMPLETED,
                            payload: {
                                logoAlt: updated_data
                            }
                        });
                    } else if(type == "system_light"){
                        dispatch({
                            type: ActionType.UPDATE_SYSTEM_LIGHT_COMPLETED,
                            payload: {
                                system_light: updated_data
                            }
                        });
                    } else if(type == "system_dark"){
                        dispatch({
                            type: ActionType.UPDATE_SYSTEM_DARK_COMPLETED,
                            payload: {
                                system_dark: updated_data
                            }
                        });
                    } else if(type == "system_logo_alt"){
                        dispatch({
                            type: ActionType.UPDATE_SYSTEM_LOGO_ALT_COMPLETED,
                            payload: {
                                system_logo_alt: updated_data
                            }
                        });
                    } else if(type == "email_header_logo") {
                        dispatch({
                            type: ActionType.UPDATE_EMAIL_HEADER_LOGO_COMPLETED,
                            payload: {
                                email_header_logo: updated_data
                            }
                        });
                    } else if(type == "email_header_logo_alt") {
                        dispatch({
                            type: ActionType.UPDATE_EMAIL_HEADER_LOGO_ALT_COMPLETED,
                            payload: {
                                email_header_logo_alt: updated_data
                            }
                        });
                    }
                    return true;
                }else{
                    dispatch(alert((res?.body as any)?.error) as any);
                }
        } catch(err) {
            console.log("response=> ", err)
        }
        return false
    }
}

export const getSiteSetting = (): any => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const init_settings = await userServices.getSiteSettings();
            dispatch({
                type: ActionType.FETCH_ESSENTIALS_SETTINGS_COMPLETED,
                payload: {
                    data_loaded: true ,
                    feature_config: init_settings.body.feature_config,
                    landing_page_ui_config: init_settings.body.landing_page_ui_config,
                    valid_domains: init_settings.body.valid_domains,
                    chat_configuration: init_settings.body.chat_configuration,
                    logo: {
                        logo: init_settings.body.logo ,
                        logoAlt: init_settings.body.logoAlt,
                        light: init_settings.body.light,
                        dark: init_settings.body.dark,
                        system_light: init_settings.body.system_light,
                        system_dark: init_settings.body.system_dark,
                        system_logo_alt: init_settings.body.system_logo_alt
                    },
                    environment: init_settings.body.environment ,
                    search_ui: init_settings.body.search_ui,
                    customer_notice: init_settings.body?.customer_notice,
                    home_page_config: init_settings.body?.home_page_config,
                    max_file_upload_size: init_settings.body?.max_file_upload_size,
                    max_file_upload_count: init_settings.body?.max_file_upload_count,
                    max_project_file_count: init_settings.body?.max_project_file_count,
                    project_file_size_limit: init_settings.body?.project_file_size_limit
                }
            })
            if(init_settings.body?.customer_notice) {
                dispatch(updatePortalType("customer_notice"))
            }
        } catch(err) {
            console.log("Error:" , err);
        }
    }
}

export const fetchAppSettings = (): any => {
    return async (dispatch: Dispatch<any>,getState: () => RootState ) => {
        try {
            const state = getState();
            const isInternal = state.auth.user.internal;
            if(isInternal){
            const site_setting = await userServices.get_app_settings();

            dispatch({
                type: ActionType.FETCH_APP_SETTINGS_COMPLETED,
                payload: {
                    id: site_setting.body._id,
                    data_loaded: true,
                    valid_domains: site_setting.body.valid_domains ,
                    logo: {
                        logo: site_setting.body.logo ,
                        logoAlt: site_setting.body.logoAlt,
                        light: site_setting.body.light,
                        dark: site_setting.body.dark,
                        system_light: site_setting.body.system_light,
                        system_dark: site_setting.body.system_dark,
                        system_logo_alt: site_setting.body.system_logo_alt
                    } ,
                    email_header: {
                        email_header_logo: site_setting.body.email_header_logo ,
                        email_header_logo_alt: site_setting.body.email_header_logo_alt
                    } ,
                    federated_key_templates: site_setting.body.federated_key_templates,
                    sendrid_dynamic_templates: site_setting.body.sendrid_dynamic_templates,
                    tab_main_heading: site_setting.body.tab_main_heading,
                    chat_configuration: site_setting.body.chat_configuration,
                    feature_config:site_setting.body.feature_config || state.siteSettings?.feature_config,
                    landing_page_ui_config:site_setting.body.landing_page_ui_config || state.siteSettings?.landing_page_ui_config,
                    ppt_templates: site_setting.body.ppt_templates,
                    ppt_template_last_modified: site_setting.body.ppt_template_last_modified,
                    search_ui: site_setting.body.search_ui || state.siteSettings?.search_ui,
                    answer_variance_threshold: site_setting.body.answer_variance_threshold,
                    api_permission_modules: site_setting.body.api_permission_modules,
                    workflow_types: site_setting.body.workflow_types,
                    customer_notice: site_setting.body?.customer_notice,
                    home_page_config: site_setting.body?.home_page_config
                }
            })
        }else{
            dispatch(getSiteSetting());
            }
        } catch(err) {
            console.log("Error:" , err);
            dispatch(alert("Please try again later (server down!)"))
        }
        return false;
    }
}

export const updateTheme = (theme: string) => {
   return (dispatch: Dispatch) => {
        dispatch({
            type: ActionType.UPDATE_THEME,
            payload: {
                theme: theme
            }
        })
   }
}

export const updateLocalSetting = (newSettings: newSettings) => {
    return async (dispatch: Dispatch<any>, getState: any) => {
        try {
            const userObj = getState().auth.user;
            const response = await userServices.updateSettings(newSettings);
            if (response.status === 200) {
                const prevSettings = getState().auth.user.settings;
                if (prevSettings.defaultChatAgent !== newSettings.defaultChatAgent) {
                    dispatch(refetchChatConfiguration());
                }

                userObj.settings.email = newSettings.email;
                userObj.settings.theme = newSettings.theme;
                userObj.settings.chatSuggestions = newSettings.chatSuggestions;
                userObj.settings.hideChatButtons = newSettings.hideChatButtons;
                userObj.settings.chatAgents = newSettings.chatAgents;
                userObj.settings.defaultChatAgent = newSettings.defaultChatAgent;
                userObj.settings.chatInterfaceMode = newSettings.chatInterfaceMode;
                dispatch({
                    type: ActionType.UPDATE_LOCAL_USER_SETTING,
                    payload: userObj.settings
                });
                dispatch(alert((response?.body as any)?.message, { position: "bottom-left" }, "success"));
                return true;
            } else {
               dispatch(alert((response?.body as any)?.error));
               return false;
            }
          } catch(err) {
            console.log("Error: ", err);
            dispatch(alert());
            return false;
          }
        }
    }

    export const addQuestionContext = (payload:{id:string,text:string,agent:string,focus:string,sources:string[]}) => {
        return async (dispatch: Dispatch , getState: any) => {
            try {
                const newObj = payload
                const id = getState().siteSettings.id;

                const chat_configuration_array = getState().siteSettings.chat_configuration ? getState().siteSettings.chat_configuration : []

                const data = {chat_configuration: [...chat_configuration_array, newObj] , action: "chat_configuration"}
                siteServices.updateSiteSettings(id , data).then(function(res){
                    if(res.status == 200) {
                        dispatch({
                            type: ActionType.ADD_NEW_QUESTION_CONTEXT,
                            payload: newObj
                        });
                    }else{
                        dispatch(alert((res?.body as any)?.error));
                    }
                  });
            } catch (error) {
                dispatch(alert())
            }
        }
    }

    export const addThresholdAction = (minThreshold: number | undefined, maxThreshold: number | undefined) => {
        return async (dispatch: Dispatch , getState: any) => {
            try {
                const newObj = {min: minThreshold , max: maxThreshold}
                const id = getState().siteSettings.id;
                const data = {answer_variance_threshold: newObj , action: "answer_variance_threshold"}
                siteServices.updateSiteSettings(id , data).then(function(res){
                    if(res.status == 200) {
                        dispatch({
                            type: ActionType.UPDATE_ANSWER_VARIANCE_THRESHOLD,
                            payload: newObj
                        });
                    }else{
                        dispatch(alert((res?.body as any)?.error));
                    }
                  });
            } catch (error) {
                dispatch(alert())
            }
        }
    }
    export const updateChatUploaderConfigurations = (name: 'max_file_upload_count' | 'max_file_upload_size', value: number | undefined) => {
        return async (dispatch: Dispatch , getState: any) => {
            try {
                const data = {
                    [name]: value ,
                    action: name
                }
                const id = getState().siteSettings.id;
                const res = await siteServices.updateSiteSettings(id , data)
                if(res.status == 200) {
                    dispatch({
                        type: ActionType.UPDATE_CHAT_UPLOADER_CONFIGURATIONS,
                        payload: data
                    });
                    return true;

                }else{
                    dispatch(alert((res?.body as any)?.error));
                }
            } catch (error) {
                dispatch(alert())
            }
            return false;
        }
    }

    export const updateProjectFileUploaderConfigurations = (name: 'max_project_file_count' | 'project_file_size_limit', value: number | undefined) => {
        return async (dispatch: Dispatch , getState: any) => {
            try {
                const data = {
                    [name]: value ,
                    action: name
                }
                const id = getState().siteSettings.id;
                const res = await siteServices.updateSiteSettings(id , data)
                if(res.status == 200) {
                    dispatch({
                        type: ActionType.UPDATE_PROJECT_FILE_UPLOADER_CONFIGURATIONS,
                        payload: data
                    });
                    return true;

                }else{
                    dispatch(alert((res?.body as any)?.error));
                }
            } catch (error) {
                dispatch(alert())
            }
            return false;
        }
    }

    export const deleteQuestionContext = ( i: number) => {
        return async (dispatch: Dispatch<any> , getState: any) => {
            try {
                const state: RootState = getState();
                const id = getState().siteSettings.id;
                const newQuestionTags = state.siteSettings?.chat_configuration?.filter((tag: any, index: number) => index !== i)
                const data = {chat_configuration: newQuestionTags  , action: "chat_configuration"}
                siteServices.updateSiteSettings(id, data).then(function(res){
                    if(res.status == 200) {
                        dispatch({
                            type: ActionType.REMOVE_QUESTION_CONTEXT,
                            payload: i
                        })
                    }else{
                        dispatch(alert((res?.body as any)?.error));
                    }
                  });
            } catch(err) {
               dispatch(alert())
            }
        }
    }
export const showGroupList = () => {
    return async (dispatch : Dispatch) => {
        try {
            dispatch(updateLoaderAction(true));
            const response = await userServices.fetchGroupLists();
            if(response.status == 200){
                dispatch({
                    type: ActionType.UPDATE_GROUP_LIST,
                    payload: response.body.groups
                });
                dispatch(updateLoaderAction(false));
            }
            dispatch(updateLoaderAction(false));
        }catch(error){
            dispatch(updateLoaderAction(false));
            dispatch(alert("Failed to fetch Group list!!",  { position: "bottom-left" }));
        }
    }
}

export const handleLoaderAction = (value: boolean) => {
    return {
        type: ActionType.SHOW_LOADING_SPINNER_BEFORE_GETTING_GORUP_LIST,
        payload: value
    }
}
export const showUsersList = () => {
    return async (dispatch : Dispatch) => {
        try {
            dispatch(handleSpinnerLoading(true));
            const response = await userServices.fetchUsersLists();
            if(response.status == 200){
                dispatch(handleSpinnerLoading(false));
                dispatch({
                    type: ActionType.UPDATE_USERS_LIST ,
                    payload: response.body.users
                });
            }
        }catch(error){
            dispatch(handleSpinnerLoading(false));
            dispatch(alert("Failed to fetch Users list!!",  { position: "bottom-left" }));
        }
    }
}

export const handleSpinnerLoading = (value: boolean) => {
    return {
        type: ActionType.UPDATE_SPINNER_LOADING_GROUPS,
        payload: value
    }

}

export const removeUserFromGroup = (group_name: string, user_ids: string[]) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
      try {
        const group_users_list = getState().auth.group_users_list;

        const updatedGroupUsersList = group_users_list.filter(
          (user: any) => !user_ids.includes(user.user_id)
        );

        const response = await userServices.removeUserFromGroup(group_name, user_ids);
        switch (response.status) {
          case 200:
            dispatch({
              type: ActionType.REMOVE_USER_FROM_GROUPS,
              payload: updatedGroupUsersList
            });
            dispatch(showUsersIngroup(group_name));
            dispatch(alert("Remove user from group successfully!", { position: "bottom-left" }, "success"));
            break;
        case 400 :case 500: case 401: case 409 :case 404 :case 403:
            dispatch(alert(response.body.error, { position: "bottom-left" }, "error"));
            break;
          default:
            dispatch(alert("Unknown error occurred", { position: "bottom-left" }, "error"));
            break;
        }
      } catch (err) {
        dispatch(alert("Failed to Remove User from list!!", { position: "bottom-left" }));
      }
    };
  };

export const addNewGroup = ( group_name: string,  description: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
           const response  = await userServices.generateNewGroup(group_name,description)
            switch (response.status) {
                case 200:
                    dispatch({
                        type: ActionType.ADD_NEW_GROUP,
                        payload: group_name
                    });
                    dispatch(alert(response.body.message, { position: "bottom-left" }, "success"));
                    break;
                case 400 :case 500: case 401: case 409 :case 404 :case 403:
                    dispatch(alert(response.body?.error, { position: "bottom-left" }, "error"));
                    break;
                default:
                    dispatch(alert("Unknown error occurred", { position: "bottom-left" }, "error"));
                    break;
            }
            dispatch(showGroupList());
        } catch(err) {
            dispatch(alert("Failed to create new Group!!",  { position: "bottom-left" }));
        }
    }
}
export const deleteGroup = ( group_name: string ) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const payload = { group_name }
            const { status, body } = await userServices.deleteCognitoGroup(payload)
            if(status == 200) {
                dispatch({
                    type: ActionType.DELETE_GROUP,
                    payload: group_name
                })
                dispatch(showGroupList());
                dispatch(alert(body.message, {}, "success"));
            }
        } catch(err) {
            dispatch(alert("Failed to delete Group!!",  { position: "bottom-left" }));
        }
    }
}

export const handleAddUserInGroup = ( group_name: string,  user_ids: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            userServices.addUserInGroup(group_name,user_ids).then(function(res){
                if(res.status == 200) {
                    // dispatch({
                    //     type: ActionType.ADD_USER_IN_GROUP,
                    //     payload: group_name
                    // })
                    dispatch(alert("User added in a group!",  { position: "bottom-left" },"success"));
                }
              });
        } catch(err) {
            dispatch(alert("Failed to add user in Group!!",  { position: "bottom-left" }));
        }
    }
}

export const showUsersIngroup = ( group_name: string) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(updateLoaderAction(true));
            const response = await userServices.getListOfUserInGroup(group_name);
            if(response.status === 200) {
                dispatch(updateLoaderAction(false));
                dispatch(updateUserListFromGroupAction(response.body.users));
            }else{
                dispatch(updateLoaderAction(false));
            }
        } catch(err) {
            dispatch(updateLoaderAction(false));
            dispatch(alert("Failed to add user in Group!!",  { position: "bottom-left" }));
        }
    }
}

export const getTemplatesAction= (workflow_type: string)=>{
    return async (dispatch: Dispatch<any>) => {
        try{
            const response = await userServices.getTemplatesList(workflow_type)
            if(response.status === 200){
                dispatch({
                    type: ActionType.UPDATE_TEMPLATE_LIST,
                    payload: response.body.templates
                })
            }else{
                dispatch(alert(response.body.error,  { position: "bottom-left" }));
            }
        }catch(err){
            console.log(err);
            dispatch(alert("Failed to load tempaltes!!",  { position: "bottom-left" }));
        }
    }
}

export const saveDefaultTemplate = (template_id:string,workflow_type:string)=>{
    return async (dispatch: Dispatch) => {
        try{
            const response = await userServices.saveDefaultTemplate(template_id,workflow_type)
            if(response.status === 200){
                dispatch(alert(`Default template selected`,{ position: "bottom-left" },"success"));
            }else{
                dispatch(alert(response.body.msg,  { position: "bottom-left" }));
            }
        }catch(err){
            console.log(err);
            dispatch(alert("Failed to save default tempaltes!!",  { position: "bottom-left" }));
        }
    }
}
export const logoLink = (file:any)=>{
    return async (dispatch: Dispatch) => {
        try{
            const response = await userServices.getLogoLink(file)
            if(response.status === 200){
                return response.body;
            }else{
                dispatch(alert("Failed to get Link!!",  { position: "bottom-left" }));
            }
        }catch(err){
            console.log(err);
            dispatch(alert("Failed to get Link!!",  { position: "bottom-left" }));
        }
        return null
    }
}

export const updateUserListFromGroupAction = (responseData: Array<UserListFromGroup>) => {
    return {
        type: ActionType.GET_LIST_OF_USERS_FROM_GROUP,
        payload: responseData
    }
}
export const updateApiCallAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_ACTION_TO_CALL_API_ONCE,
        payload: value
    }
}
export const updateLoaderAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_GROUP_LIST_USER_LOADER_ACTION,
        payload: value
    }
}

export const updateCustomerNoticeAction = (notice: string) => {
    return async (dispatch: Dispatch , getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const data = {customer_notice: notice  , action: "customer_notice"}
            siteServices.updateSiteSettings(id, data).then(function(res){
                if(res.status == 200) {
                    dispatch(alert(res.body?.message, { position: "bottom-left" }, "success"));
                }else{
                    dispatch(alert((res?.body as any)?.error));
                }
              });
        } catch(err) {
            console.log(err);
            dispatch(alert("Failed to update customer notice!!",  { position: "bottom-left" }));
            return false;
        }
    }
}

export const updateCustomerNotice = (notice: string) => {
    return {
        type: ActionType.UPDATE_CUSTOMER_NOTICE,
        payload: notice
    }
}

export const customerNoticeAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await siteServices.customerNoticeService();
            if (response.status === 200) {
            } else {
                dispatch(alert("Failed to update customer notice!!", { position: "bottom-left" }));
            }
        } catch (err) {
            dispatch(alert("Failed to update customer notice!!", { position: "bottom-left" }));
        }
    }
}

export const updateCustomerNoticeDismissed = (value: boolean) => {
    return {
        type: ActionType.UPDATE_CUSTOMER_NOTICE_DISMISSED,
        payload: value
    }
}
