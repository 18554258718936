export const getProjects = `
    query getProjects($page: Int, $limit: Int, $offset: Int) {
      getProjects(page: $page, limit: $limit, offset: $offset){
        page
        has_next_page
        projects {
          id
          topic
          created_by{
            user_id
            name
            email
            chat_role
          }
          metadata
          created_at
        }
      }
    }
`;
