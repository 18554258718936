import { Colors } from "style/color";

export const chatColors = {
    dark:{
        ChatVisualBg: Colors.COARSE_WOOL,
        ChatContentTextColor: Colors.WHITE,
        ChatQuestionCardBg: Colors.BLACK,
        ChatItemBorderColor: Colors.TRANSPARENT,
        ChatItemTextColor: Colors.AMOUR_WHITE,
        ChatItemSubTextColor: Colors.ELEMENTAL_GREY,
        ChatItemActiveBg: Colors.PUNCH_OUT_GLOVE,
        ChatInputBg: Colors.BLACK,
        ChatInputColor: Colors.WHITE,

        ChatClearHistoryBgColor:"#1d1d24",
        ChatClearHistoryTextColor: Colors.WHITE,
        chatQuestionBorderColor:"#616269",
        chatQuestionBgColor:"#1B1C31",
        shadowColor: Colors.TRANSPARENT,
        chatQuestionTextColor: Colors.WHITE,
        ChatContentHeadingColor: Colors.WHITE,
        SkyBlueColor: "#171717",
        DataGridSkyBlueColor:"#1d1d29",
        SkyBlueBorderColor: "#404040",
        ChatInputBgColor:"#262631",
        ChatTextColor: Colors.WHITE,
        lightBlackColor: Colors.WHITE,
        ShellSvgColor: Colors.WHITE,
        NewConversationColor:"#262631",
        NewConversationBorderColor: Colors.TRANSPARENT,
        newConversationHeadingColor: Colors.AMOUR_WHITE,
        chatConversationTextColor: Colors.DEBUG_BTN_COLOR,
        UtteranceProcessedChat:"transparent",
        UtteranceProcessedChatBorder:"#999999",
        UtteranceProcessedChatColor:"#2C2D3D",
        UtteranceProcessedChatOuterBorder:"#616269",
        UtteranceProcessedChatTextColor: Colors.WHITE,
        SearchConversationBg: Colors.WHITE,
        UtteranceProcessedChatText: Colors.WHITE,
        ChatButtonListingBgColor:"transparent",
        UtteranceProcessedButtonColor:"transparent",
        ChatButtonListingBorderColor:"rgba(97, 98, 105, 0.95)",
        UtteranceProcessedTextColor: Colors.WHITE,
        ChatErrorColor:"#F08482",
        debugBgColor:"#292929",
        menuIconColor: Colors.WHITE,
        configDropdownBgColor:"#343541",
        intentsNameBgColor:"#343541",
        intentsBorderColor:"#3B3D5A",
        intentsNameTextColor: Colors.WHITE,
        intentsSvgColor: Colors.WHITE,
        EditorSideBarTreeListSelectBackground:"#212B36",
        TreeListItemBgColor:"#343541",
        NextPrevBtnColor:"#272727",
        mentionInputSuggestionBorderColor: "#3F3F3F",
        MentionInputTextColor: Colors.AMOUR_WHITE,
        MentionInputSuggestionTextColor: Colors.AMOUR_WHITE,
        ChatAvatarBorderColor: Colors.WHITE,
        SourceRecordsHeaderBg: "#3A3A3A",
        SourceRecordsText: "#ECECEC",
        SourceRecordsFieldValue: "#ECECEC",
        SourceRecordsListBg: "#292929",
        SourceHeaderBorder: "#575757",
        SourceRecordsBottomBg: "#343434",
        CompareCrossButtonBg: "#3A3A3A",
        CompareCrossButtonBgHover: "#484848",
        FileUploadBg: "#171717",
        FileUploadTextColor: Colors.WHITE,
        ChatSettingIconColor: "#ECECEC",
        CrossIconColor: "#B4B4B4",
        rightConatinerBorder: "#D9D9D938",
        AdvancedSidebarLabelColor: "#B4B4B4",
        AdvanceSdebarTextColor: "#ECECEC",
        AdvanceSdebarPillBgColor: "transparent",
        AdvanceSdebarFileItemBgColor: "#171717",
        AdvanceSdebarFileItemBorder: "#FFFFFF1C",
        AdvancedSidebarFileTypeColor: "#ECECEC",
        AdvanceSdebarActionButtonBgColor: "#FFFFFF0D",
        AdvanceSdebarActionButtonBorderColor: "#FFFFFF38",
        AdvanceWrapperBgColor: "#1E1E1E",
        DebugOperationTextColor: "#ECECEC",
    },

    light:{
        ChatVisualBg: Colors.WHITE,
        ChatContentTextColor: Colors.DARK_CHARCOAL,
        ChatQuestionCardBg: Colors.WHITE,
        ChatItemBorderColor: Colors.DISABLED_GRAY,
        ChatItemTextColor: Colors.DARK_CHARCOAL,
        ChatItemSubTextColor: Colors.ELEMENTAL_GREY,
        ChatItemActiveBg: Colors.AZUL,
        ChatInputBg: Colors.WHITE,
        ChatInputColor: Colors.BLACK,

        ChatClearHistoryBgColor:"#ffcccc",
        ChatClearHistoryTextColor:"#a50000",
        chatQuestionBorderColor:"#BBD6FF",
        chatQuestionBgColor:"#ECF3FE",
        shadowColor:"rgba(0, 0, 0, 0.25)",
        ChatLinkTextColor:"#2964AA",
        chatQuestionTextColor: Colors.BLACK,
        ChatContentHeadingColor: Colors.BLACK,
        SkyBlueColor:"#ECF3FE",
        DataGridSkyBlueColor:"#ECF3FE",
        SkyBlueBorderColor:"#BBD6FF",
        ChatTextColor: Colors.BLACK,
        lightBlackColor: Colors.DARK_CHARCOAL,
        ShellSvgColor:"#2158e8",
        NewConversationColor:"#F9F9F9",
        NewConversationBorderColor:"#DBDBDB",
        newConversationHeadingColor: Colors.OPTION_LIST_TEXT_COLOR,
        SearchConversationBg: Colors.WHITE,
        UtteranceProcessedChatText:"#2c2c2c",
        ChatButtonListingBgColor: Colors.WHITE,
        UtteranceProcessedButtonColor: Colors.TRANSPARENT,
        ChatButtonListingBorderColor:"#BBD6FF",
        UtteranceProcessedTextColor:"#2c2c2c",
        ChatErrorColor:"#B02725",
        debugBgColor:"#ffffff00",
        menuIconColor:"#444746",
        configDropdownBgColor: Colors.WHITE,
        intentsNameBgColor: Colors.WHITE,
        intentsBorderColor:"#E8E8E8",
        intentsNameTextColor: Colors.BLACK,
        intentsSvgColor: Colors.BLACK,
        EditorSideBarTreeListSelectBackground: Colors.WHITE,
        TreeListItemBgColor:"#F4F4F4",
        chatConversationTextColor: Colors.OPTION_LIST_TEXT_COLOR,
        UtteranceProcessedChat:"#fff",
        UtteranceProcessedChatBorder:"#d9d9d9",
        UtteranceProcessedChatColor:"#d9e8ff",
        UtteranceProcessedChatOuterBorder:"#BBD6FF",
        UtteranceProcessedChatTextColor: Colors.BLACK,
        NextPrevBtnColor:"#E7EFFA",
        mentionInputSuggestionBorderColor: "#DEDEDE",
        MentionInputTextColor: Colors.OPTION_LIST_TEXT_COLOR,
        MentionInputSuggestionTextColor: Colors.GREY_WHITE,
        ChatAvatarBorderColor: Colors.CHAT_AVATAR_BORDER_COLOR,
        SourceRecordsHeaderBg: "#E7EFFA",
        SourceRecordsText: "#007AA5",
        SourceRecordsFieldValue: "#2C2C2C",
        SourceRecordsListBg: Colors.WHITE,
        SourceHeaderBorder: "#D9D9D9",
        SourceRecordsBottomBg: "#D9D9D9",
        CompareCrossButtonBg: "#727272",
        CompareCrossButtonBgHover: "#545454",
        FileUploadBg: "#f9f9f9",
        FileUploadTextColor: Colors.BLACK,
        ChatSettingIconColor: "#727272",
        CrossIconColor: "#fff",
        rightConatinerBorder: "#D9D9D9",
        AdvancedSidebarLabelColor: "#727272",
        AdvanceSdebarTextColor: "#2C2C2C",
        AdvanceSdebarPillBgColor: "#fff",
        AdvanceSdebarFileItemBgColor: "#E7EFFA",
        AdvanceSdebarFileItemBorder: "#E7EFFA",
        AdvancedSidebarFileTypeColor: "#727272",
        AdvanceSdebarActionButtonBgColor: "#fff",
        AdvanceSdebarActionButtonBorderColor: "#00000012",
        AdvanceWrapperBgColor: "#ffffff",
        DebugOperationTextColor: "#2C2C2C",
    }
};
