
export const semanticCacheColors = {
    dark:{
        SemanticCacheTitleColor:'#ffffff',
        SemanticCacheBorderColor:'#2C2C2C',
        HeaderBorderColor:'#383838',
        SemanticCacheBgColor:'#212121',
        toggleOptionBorderColor:'#5e5a5a',
        toggleOptionTextColor:'#aaa7a7',
        DropdownListBorderColor:'#5e5a5a',
        DropdownItemHoverColor:'#323446',
        ClearFilterButtonBorderColor:'#5f5a5a',
        ClearFilterButtonBgColor:'#4a4a4a',
        ClearFilterButtonTextColor:'#ffffff',
        ClearFilterButtonHoverColor:'#BE0000',
        SourceBadgeBgColor:'#007aa012',
        SourceBadgeTextColor:'#007aa0',
        toggleOptionHoverBgColor:'#373737',
        feedbackTextColor:'#b4b4b4',
        cancelBtnTextColor:'#ececec',
        saveActionBtnBgColor:'#3a3a3a',
        saveActionBtnBorderColor:'#3a3a3a',
        saveActionBtnTextColor:'#fff',
        thumbButtonHoverBgColor:'#0dc6ff33',
        thumbButtonTextColor:'#dbdbdb',
    },
    light:{
        SemanticCacheTitleColor:'#2C2C2C',
        SemanticCacheBorderColor:'#0000001A',
        HeaderBorderColor:'#0000001A',
        SemanticCacheBgColor:'#fff',
        toggleOptionBorderColor:'#D9D9D9',
        toggleOptionTextColor:'#727272',
        DropdownListBorderColor:'#00000012',
        DropdownItemHoverColor:'#f0f0f0',
        ClearFilterButtonBorderColor:'#FCC3C3',
        ClearFilterButtonBgColor:'transparent',
        ClearFilterButtonTextColor:'#BE0000',
        ClearFilterButtonHoverColor:'#fcc3c3',
        SourceBadgeBgColor:'#eef2ff',
        SourceBadgeTextColor:'#007AA0',
        toggleOptionHoverBgColor:'#f1f1f1',
        feedbackTextColor:'#727272',
        cancelBtnTextColor:'#727272',
        saveActionBtnBgColor:'#1D4EE5',
        saveActionBtnBorderColor:'#1D4EE5',
        saveActionBtnTextColor:'#fff',
        thumbButtonHoverBgColor:'#007AA01C',
        thumbButtonTextColor:'#666666',
    },
}
