import { LocalDriveFolders } from 'types/appConnection';
import { ActionType } from '../action-types'

type AppConnectionsState = {
    appConnections: any[],
    isFetchingFolders: boolean,
    error: string | null,
    localDriveFolders: LocalDriveFolders[],
};

export const initialState: AppConnectionsState = {
    appConnections: [],
    isFetchingFolders: false,
    error: null,
    localDriveFolders: []
};

const reducer = (state: AppConnectionsState = initialState, action: any): AppConnectionsState => {
    switch (action.type) {
        case ActionType.FETCH_LOCAL_DRIVE_FOLDERS:
            return { ...state, localDriveFolders: action.payload };
        case ActionType.IS_FETCHING_FOLDERS:
            return { ...state, isFetchingFolders: action.payload };
        default:
            return state;
    }
}

export default reducer;