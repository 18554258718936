import { ActionType } from "state/action-types"
import { Dispatch } from "redux";
import { RootState } from "state";
import { alert } from "./customRouter";
import { deleteSemanticCache, deleteSemanticCacheQuestion, getSemanticAgent, getSemanticCacheAnswer, getSemanticCacheData, getSemanticCacheQuestions, getSemanticFocus, getSemanticSource, updateUserFeedback } from "services/semanticCacheServices";


const limit = 25;

export const fetchSemanticCacheAction = (page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if(page === 1){
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_DATA,
                    payload: []
                });
                dispatch(isSemanticCacheLoader(true));
            }else{
                dispatch(setCommonLoaderForSemanticCache(true));
            }
            const selected_agents = getState().semanticCache.selected_agents;
            const selected_focuses = getState().semanticCache.selected_focuses;
            const selected_data_sources = getState().semanticCache.selected_data_sources;
            const is_private = getState().semanticCache.is_private_active;

            const payload = {
                limit: limit,
                page_number: page,
            };
            const withFiltersPayload = {
                limit: limit,
                page_number: page,
                filters: {
                    data_sources: selected_data_sources.length > 0 ? selected_data_sources : [],
                    focus: selected_focuses.length > 0 ? selected_focuses : [],
                    agent: selected_agents.length > 0 ? selected_agents : [],
                    is_private: is_private
                }
            };
            const is_with_filters = selected_agents.length > 0 || selected_focuses.length > 0 || selected_data_sources.length > 0 || is_private === true;
            const {status, body} = await getSemanticCacheData(is_with_filters ? withFiltersPayload : payload);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_DATA,
                    payload: page === 1 ? body.data.semantic_cache_records : [...getState().semanticCache.semantic_cache_data, ...body.data.semantic_cache_records || []],
                });
                dispatch(isSemanticCacheLoader(false));
                dispatch(setCommonLoaderForSemanticCache(false));
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_HAS_MORE,
                    payload: body.data.has_next
                });
                return true;
            } else {
                dispatch(alert(body?.error));
                dispatch(isSemanticCacheLoader(false));
                dispatch(setCommonLoaderForSemanticCache(false));
            }
        } catch (error) {
            console.log(error, "error");
            dispatch(isSemanticCacheLoader(false));
            dispatch(setCommonLoaderForSemanticCache(false));
        }
        dispatch({
            type: ActionType.SET_SEMANTIC_CACHE_HAS_MORE,
            payload: false
        });
        return false;
    }
};

export const isSemanticCacheLoader = (value: boolean) => {
    return {
        type: ActionType.SET_SEMANTIC_CACHE_LOADER,
        payload: value
    }
}

export const setCommonLoaderForSemanticCache = (value: boolean) => {
    return {
        type: ActionType.SET_COMMON_LOADER_FOR_SEMANTIC_CACHE,
        payload: value
    }
}

export const deleteCacheAction = (id: string, setIsLoading: (isLoading: boolean) => void) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            setIsLoading(true);
            const state = getState().semanticCache.semantic_cache_data;
            const {status, body} = await deleteSemanticCache(id);
            if (status === 200) {
                const newPayloads = state.filter((item) => item.id !== id);
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_DATA,
                    payload: newPayloads,
                });
                setIsLoading(false);
                dispatch(alert(body?.message, {position: "bottom-left"}, "success"));
            }else{
                dispatch(alert(body?.error));
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error, "error");
            setIsLoading(false);
        }
    }
}

export const fetchSemanticCacheQuestions = (id: string, page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if(page === 1){
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_QUESTIONS_DATA,
                    payload: []
                });
                dispatch(isSemanticCacheLoader(true));
            }else{
                dispatch(setCommonLoaderForSemanticCache(true));
            }
            const {status, body} = await getSemanticCacheQuestions(id, page, limit);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_QUESTIONS_DATA,
                    payload: page === 1 ? body.data.semantic_cache_questions : [...getState().semanticCache.semantic_cache_questions, ...body.data.semantic_cache_questions || []],
                });
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_QUESTIONS_HAS_MORE,
                    payload: body.data.has_next
                });
                dispatch(isSemanticCacheLoader(false));
                dispatch(setCommonLoaderForSemanticCache(false));
                return true;
            } else {
                dispatch(alert(body?.error));
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_QUESTIONS_HAS_MORE,
                    payload: false
                });
                dispatch(isSemanticCacheLoader(false));
                dispatch(setCommonLoaderForSemanticCache(false));
                return false;
            }
        } catch (error) {
            console.log(error, "error");
            dispatch(isSemanticCacheLoader(false));
            dispatch(setCommonLoaderForSemanticCache(false));
        }
        dispatch({
            type: ActionType.SET_SEMANTIC_CACHE_QUESTIONS_HAS_MORE,
            payload: false
        });
    }
}

export const setIsPrivateActiveAction = (value: boolean) => {
    return {
        type: ActionType.SET_IS_PRIVATE_ACTIVE,
        payload: value
    }
}

export const fetchSemanticAgent = (page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const {status, body} = await getSemanticAgent(page, 10);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_SEMANTIC_AGENT_DATA,
                    payload: page === 1 ? body.data.agents : [...getState().semanticCache.agents, ...body.data.agents || []],
                });
                dispatch({
                    type: ActionType.SET_SEMANTIC_AGENT_HAS_MORE,
                    payload: body.data.has_next
                });
            } else {
                dispatch(alert(body?.error));
            }
        } catch (error) {
            console.log(error, "error");
            dispatch({
                type: ActionType.SET_SEMANTIC_AGENT_HAS_MORE,
                payload: false
            });
        }
    }
}

export const fetchSemanticFocus = (page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const {status, body} = await getSemanticFocus(page, limit);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_SEMANTIC_FOCUS_DATA,
                    payload: page === 1 ? body.data.focuses : [...getState().semanticCache.focuses, ...body.data.focuses || []],
                });
                dispatch({
                    type: ActionType.SET_SEMANTIC_FOCUS_HAS_MORE,
                    payload: body.data.has_next
                });
            } else {
                dispatch(alert(body?.error));
            }
        } catch (error) {
            console.log(error, "error");
            dispatch({
                type: ActionType.SET_SEMANTIC_FOCUS_HAS_MORE,
                payload: false
            });
        }
    }
}

export const fetchSemanticSource = (page: number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const is_private = getState().semanticCache.is_private_active;
            const {status, body} = await getSemanticSource(page, limit, is_private);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_SEMANTIC_SOURCE_DATA,
                    payload: page === 1 ? body.data.data_sources : [...getState().semanticCache.data_sources, ...body.data.data_sources || []],
                });
                dispatch({
                    type: ActionType.SET_SEMANTIC_SOURCE_HAS_MORE,
                    payload: body.data.has_next
                });
            } else {
                dispatch(alert(body?.error));
            }
        } catch (error) {
            console.log(error, "error");
            dispatch({
                type: ActionType.SET_SEMANTIC_SOURCE_HAS_MORE,
                payload: false
            });
        }
    }
}

export const setSelectedAgents = (value: Array<string>) => {
    return {
        type: ActionType.SET_SELECTED_AGENTS,
        payload: value
    }
}

export const setSelectedFocuses = (value: Array<string>) => {
    return {
        type: ActionType.SET_SELECTED_FOCUSES,
        payload: value
    }
}

export const setSelectedDataSources = (value: Array<string>) => {
    return {
        type: ActionType.SET_SELECTED_DATA_SOURCES,
        payload: value
    }
}

export const resetAgentFocusDataSourceAction = () => {
    return {
        type: ActionType.RESET_AGENT_FOCUS_DATA_SOURCE,
    }
}

export const resetSelectedAgentFocusDataSourceAction = () => {
    return {
        type: ActionType.RESET_SELECTED_AGENT_FOCUS_DATA_SOURCE,
    }
}

export const setIsOpenEditUserFeedback = (value: boolean) => {
    return {
        type: ActionType.SET_IS_OPEN_EDIT_USER_FEEDBACK,
        payload: value
    }
}

export const deleteSemanticCacheQuestionAction = (id: string, setIsLoading: (isLoading: boolean) => void) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            setIsLoading(true);
            const state = getState().semanticCache.semantic_cache_questions;
            const {status, body} = await deleteSemanticCacheQuestion(id);
            if (status === 200) {
                const newPayloads = state.filter((item) => item.question_id !== id);
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_QUESTIONS_DATA,
                    payload: newPayloads,
                });
                setIsLoading(false);
                dispatch(alert(body?.message, {position: "bottom-left"}, "success"));
            }else{
                dispatch(alert(body?.error));
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error, "error");
            setIsLoading(false);
        }
    }
}

export const storeSemanticAnswerCacheAction = (data: any) => {
    return {
        type: ActionType.STORE_SEMANTIC_ANSWER_CACHE,
        payload: data
    }
}

export const fetchSemanticCacheAnswer = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(isSemanticCacheLoader(true));
            const {status, body} = await getSemanticCacheAnswer(id);
            if (status === 200) {
                dispatch({
                    type: ActionType.SET_SEMANTIC_CACHE_ANSWER,
                    payload: body,
                });
                dispatch(isSemanticCacheLoader(false));
            }else{
                dispatch(alert(body?.error));
            }
            dispatch(isSemanticCacheLoader(false));
        } catch (error) {
            console.log(error, "error");
            dispatch(isSemanticCacheLoader(false));
        }
    }
}

export const setPreservedSelectedAgentsFocusesDataSourcesAction = (value: Array<string>) => {
    return {
        type: ActionType.SET_PRESERVED_SELECTED_AGENTS_FOCUSES_DATA_SOURCES,
        payload: value
    }
}

export const updateUserFeedbackAction = (value: string, answer_id?: string|number) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const selected_cache_answer = getState().semanticCache.selected_cache_answer;

            const payload = {feedback: value, question_id: selected_cache_answer.question_id};
            const chatPayload = {answer_id: answer_id, feedback: value};
            const apiPayload = answer_id ? chatPayload : payload;

            dispatch(setSubmitFeedbackLoader(true));
            const {body, status} = await updateUserFeedback(apiPayload);
            if (status === 200) {
                dispatch({
                    type: ActionType.UPDATE_USER_FEEDBACK,
                    payload: value
                });

                const semantic_cache_questions = getState().semanticCache.semantic_cache_questions;
                const selected_cache_answer_index = semantic_cache_questions.findIndex((item) => item.question_id === selected_cache_answer.question_id);
                if(selected_cache_answer_index !== -1){
                    semantic_cache_questions[selected_cache_answer_index].reaction = value;
                }

                dispatch(alert(body?.message, {position: "bottom-left"}, "success"));
                dispatch(setSubmitFeedbackLoader(false));
            }else{
                dispatch(alert(body?.error));
            }
            dispatch(setSubmitFeedbackLoader(false));
        } catch (error) {
            console.log(error, "error");
            dispatch(setSubmitFeedbackLoader(false));
        }
    }
}

export const setSubmitFeedbackLoader = (value: boolean) => {
    return {
        type: ActionType.SET_SUBMIT_FEEDBACK_LOADER,
        payload: value
    }
}