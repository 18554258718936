// This is used to get the delta debug logs of a sub message.

export const getDelta = `
  query getDelta($sub_message_id: String!, $delta_id: String!) {
    getDelta(sub_message_id: $sub_message_id, delta_id: $delta_id){
      delta {
        delta_id
        delta_type
        delta
        delta_data_type
        debug_logs {
          debug_type
          debug_time
          debug_message
        }
        created_at
        updated_at
      }
    }
  }
`;
