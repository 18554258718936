/* Subscription for receiving messages sent by a user identified by userId.
  This subscription allows clients to receive real-time updates when a new message
  is sent by the specified user.

  This subscription is solely used to receive updates, not to send messages.
  Additionally, we utilize its response to display real-time updates across multiple screens simultaneously.
  These updates are only received when we maintain a constant connection with this subscription. */

export const eventReceiver = `
  subscription EventReceiver($user_id: String!) {
  eventReceiver(user_id: $user_id){
    created_at
    user_id
    event_metadata {
      event_data {
        answer_id
        created_at
        id
        is_visually_hidden
        question_id
        question_version_number
        recepient
        message_metadata {
          answer_version_number
          focus
          message_intent
          sources
          processing_status {
            reason
            state
          }
          user_message {
            author {
              role
              tool
              metadata
            }
            content {
            type
            message
            metadata
            }
            metadata
          }
        }
        sub_message_metadata {
          answer_version_number
          id
          readout_title
          processing_status {
            reason
            state
          }
          delta {
            delta_id
            delta
            delta_type
            delta_data_type
            created_at
            updated_at
          }
        }
      }
      event_message
    }
    event_type
    id
    thread_metadata {
      access_scope
      file_upload_count
      agent
      created_at
      id
      is_chat_processed
      is_closed_chat
      is_pinned_chat
      topic
      metadata
      users {
        chat_role
        email
        name
        user_id
      }
    }
    author {
      metadata
      role
      tool
    }
    content {
        type
        metadata
        message
        project{
          project{
            id
            topic
            created_at
            created_by{
              name
              email
              user_id
              chat_role
            }
            metadata
          }
          project_metadata{
            agent
            metadata
            attachments{
              id
              name
              mimetype
              thumbnail
              s3_file_id
              metadata
              status
            }
          }
        }
      }
  }
}
`;
