// This mutation is used to submit user query inside a new blank Chat.

export const updateProject = `
  mutation updateProject($project_id: String!, $type: String, $metadata: String) {
    updateProject(project_id: $project_id, type: $type, metadata: $metadata){
        event_type
        id
        created_at
        user_id
        content {
            type
            metadata
            message
            project{
                project{
                    id
                    topic
                    created_at
                    created_by{
                        name
                        email
                        user_id
                        chat_role
                    }
                    metadata
                }
                project_metadata{
                    agent
                    metadata
                    attachments{
                        id
                        name
                        mimetype
                        thumbnail
                        s3_file_id
                        metadata
                        status
                    }
                }
            }
        }
        event_metadata{
        event_message
        event_data{
            id
            question_id
            answer_id
            question_version_number
            is_visually_hidden
            created_at
            recepient
            message_metadata{
            focus
            sources
            processing_status{
                state
                reason
            }
            message_intent
            answer_version_number
            }
            sub_message_metadata{
            id
            delta{
                delta_data_type
                delta_type
                delta
                created_at
                updated_at
            }
            processing_status{
                state
                reason
            }
            readout_title
                
            
            }
        }
        }
        author{
        role
        tool
        metadata
        }
        thread_metadata{
        id
        topic
        users{
            user_id
            name
            email
            chat_role
        }
        agent
        created_at
        access_scope
        is_pinned_chat
                is_closed_chat
        is_chat_processed
        }
    }
    }

`;

