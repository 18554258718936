export const chatConfigColors = {
    dark:{
        WorkflowFormBgColor: '#292929',
        WorkflowFormColor: '#292929',
        WorkflowFormBorderColor: '#FFFFFF38',
        WorkflowFormShadowColor: '#FFFFFF40',
        WorkflowFormHeadingColor: '#ECECEC',
        WorkflowFormLabelColor: "#B4B4B4",
        InputBgColor: "transparent",
        InputBorderColor: "#FFFFFF4D",
        InputTextColor: "#ECECEC",
        cancelBtnBorderColor: "#7272724D",
        FormCrossIconBgColor: '#3A3A3A',
        FormCrossIconHoverBgColor:'#c5c5c545',
        SelectBgColor: '#2D2D2D',
        tableTdColor: '#ECECEC',
        tableTrBgColor: '#292929',
        tableThtextColor:'#B4B4B4',
        tableHeadingBgColor: '#007AA0',
        tableHeadingTextColor: '#ffffff',
        DeepAnalysisTextBgColor: '#007AA012',
        DeepAnalysisTextBorderColor: '#007AA0',
        tableOuterBoderColor: '#383838',
        tableOuterBGColor: '#1E1E1E',
        CreateBtnBgColor: '#FFFFFF38',
        CreateBtnBorderColor: '#D2D2D22E',
        CreateBtnShadowColor: '#00000029',
        actionBtnBgColor: '#FFFFFF0D',
        actionBtnBorderColor: '#FFFFFF38',
        HrBorderColor: '#383838',
        actionBtnHoverBgColor: '#000',
        WorkflowFormHeaderBorderColor: '#383838',
        WorkFlowHeaderSvgIcon:'#fff',
        FormSubmitBGColor:'#3A3A3A',
        CancelBtnTextColor:'#ECECEC',
        tabInActiveTextColor: '#B4B4B4',
        tabInActiveBgColor : '#3A3A3A',
        paginationActiveBgColor: '#525252',
        actionBtnSvgColor: '#fff',
        labelTextColor: '#fff',
        OptionItemHoverColor:'#505050',
        CreateBtnHoverBgColor:'#bdbdbd38',
        DropdownBgColor:'#1b1b32',
        DropDownItemHoverColor:'#323446',
        AgentCardBgColor:'#1b1b32',
        PopupOverlayColor:'#181616BF',
        ConnectCancelButtonColor: '#ECECEC',
        ConnectCancelButtonBG: '#3A3A3A',
        PageContainerBgColor:'#171717',
        actionColumnBgColor:'#292929',
        iconBgColor:'#ffffff',
        viewTdButtonBgColor:'#4a4a4a',
        viewTdButtonBorderColor:'#4d4d4d',
        viewTdButtonTextColor:'#fff',
        BreadcrumbTextColor:'#ececec',
        buttonDisabledBgColor:'#FFFFFF38',
        buttonDisabledBorderColor:'#FFFFFF38',
        buttonDisabledTextColor:'#ffffff',
        saveButtonDisabledTextColor:'#ffffff',
        viewTdButtonHoverBgColor:'#363636',
        viewTdButtonDisbaledBgColor:'#797979',
        disbaledInputBgColor:'#404040',
        cardBgColor:'#FFFFFF38',
        cardBorderColor:'#FFFFFF38',
        indcatorLabelTextColor:'#ECECEC',
        BtnSvgPathColor:'#ffffff',
        cardBgHoverColor:'#2f2f2f',
        calenderSvgColor:'#ffffff',
        ReferenceContainerBordercolor:'#535353',
        CenterLockIconBgColor:'#FFFFFF38',
        sidebarBgColor:'#292929',
        DropdownItemBorderColor:'#ffffff4a',
        spinnerLoaderBorderColor:'#fff',
        CancelBtnBgColor:'#828282',
    },

    light:{
        WorkflowFormBgColor: '#EFF3FD',
        WorkflowFormColor: '#EFF3FD',
        WorkflowFormBorderColor: '#D9D9D9',
        WorkflowFormShadowColor: '#00000026',
        WorkflowFormHeadingColor: '#2C2C2C',
        WorkflowFormLabelColor: "#727272",
        InputBgColor: "#fff",
        InputBorderColor: "#0000001A",
        InputTextColor: "#2C2C2C",
        cancelBtnBorderColor: "#727272",
        FormCrossIconBgColor: '#0000000d',
        FormCrossIconHoverBgColor:'#41414145',
        SelectBgColor: '#fff',
        tableTdColor: '#2C2C2C',
        tableTrBgColor: '#eff3fd',
        tableThtextColor:'#727272',
        tableHeadingBgColor: '#bae3ff',
        tableHeadingTextColor: '#2C2C2C',
        DeepAnalysisTextBgColor: '#F0F1FA',
        DeepAnalysisTextBorderColor: 'transparent',
        tableOuterBoderColor: '#d9d9d9',
        tableOuterBGColor: 'transparent',
        CreateBtnBgColor: '#2058E8',
        CreateBtnBorderColor: '#2058E8',
        CreateBtnShadowColor: '#2264E51F',
        actionBtnBgColor: 'transparent',
        actionBtnHoverBgColor: '#f2f2f2',
        actionBtnBorderColor: '#EAEAEA',
        HrBorderColor: '#0000001A',
        WorkflowFormHeaderBorderColor: '#DCE0E9',
        WorkFlowHeaderSvgIcon:'#787878',
        FormSubmitBGColor:'#1d4ee5',
        CancelBtnTextColor:'#727272',
        tabInActiveTextColor: '#727272',
        tabInActiveBgColor : '#FFFFFF',
        paginationActiveBgColor: '#2058e9',
        actionBtnSvgColor: '#727272',
        labelTextColor: '#727272',
        OptionItemHoverColor:'#f0f0f0',
        CreateBtnHoverBgColor:'#0642db',
        DropdownBgColor:'#fff',
        DropDownItemHoverColor:'#f1f1f1',
        AgentCardBgColor:'transparent',
        PopupOverlayColor:'rgba(217, 217, 217, 0.50)',
        ConnectCancelButtonColor: '#727272',
        ConnectCancelButtonBG: '#F2F2F2',
        PageContainerBgColor:'#fff',
        actionColumnBgColor:'#f5f9ff',
        iconBgColor:'#727272',
        viewTdButtonBgColor:'#EDEDED',
        viewTdButtonBorderColor:'#E2E2E2',
        BreadcrumbTextColor:'#727272',
        buttonDisabledBgColor:'#EEEFF3',
        buttonDisabledBorderColor:'#E5E5E5',
        buttonDisabledTextColor:'#2C2C2C',
        saveButtonDisabledTextColor:'#727272',
        viewTdButtonHoverBgColor:'#e0e0e0',
        viewTdButtonDisbaledBgColor:'#D9D9D9',
        disbaledInputBgColor:'#00000021',
        cardBgColor:'#ffffff',
        cardBorderColor:'#D7DEE9',
        indcatorLabelTextColor:'#727272',
        BtnSvgPathColor:'#2C2C2C',
        cardBgHoverColor:'#F3F4F6',
        calenderSvgColor:'#4b5563c',
        ReferenceContainerBordercolor:'#e5e7eb',
        CenterLockIconBgColor:'#E7EFFA',
        sidebarBgColor:'#EEF2F8',
        DropdownItemBorderColor:'#00000012',
        spinnerLoaderBorderColor:'#525364',
        CancelBtnBgColor:'transparent',
    }
};
