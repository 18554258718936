// This mutation is used to submit user query inside a new blank Chat.

export const newChat = `
  mutation newChat($topic: String!, $agent: String  ,$metadata:String) {
    newChat(topic: $topic, agent: $agent, metadata: $metadata) {
      created_at
      user_id
      event_metadata {
        event_data {
          answer_id
          created_at
          id
          is_visually_hidden
          question_id
          question_version_number
          recepient
          message_metadata {
            answer_version_number
            focus
            message_intent
            sources
            processing_status {
              reason
              state
            }
          }
          sub_message_metadata {
            answer_version_number
            id
            readout_title
            processing_status {
              reason
              state
            }
            delta {
              created_at
              delta
              delta_data_type
              delta_type
              updated_at
            }
          }
        }
        event_message
      }
      event_type
      id
      thread_metadata {
        access_scope
        agent
        created_at
        id
        is_chat_processed
        file_upload_count
        is_closed_chat
        is_pinned_chat
        metadata
        topic
        users {
          chat_role
          email
          name
          user_id
        }
      }
      author {
        metadata
        role
        tool
      }
      content {
        message
        metadata
        type
        project{
          project{
            id
            topic
            created_at
            created_by{
              name
              email
              user_id
              chat_role
            }
            metadata
          }
          project_metadata{
            agent
            metadata
            attachments{
              id
              name
              mimetype
              thumbnail
              s3_file_id
              metadata
            }
          }
        }
      }
    }
  }
`;
