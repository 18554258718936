import { ActionType } from "state/action-types";
import { semanticCacheState } from "types/semanticCacheTypes";


export const initialState: semanticCacheState = {
    semantic_cache_data: [],
    has_more_cache: true,
    is_semantic_cache_loader: false,
    is_common_loader_for_semantic_cache: false,
    is_private_active: false,
    isOpenEditUserFeedback: false,
    agents: [],
    has_more_agents: true,
    focuses: [],
    has_more_focuses: true,
    data_sources: [],
    has_more_sources: true,
    selected_agents: [],
    selected_focuses: [],
    selected_data_sources: [],
    semantic_cache_questions: [],
    has_more_semantic_cache_questions: true,
    selected_cache_answer: {
      vector_pk: "",
      question: "",
      question_id: "",
      last_access: null,
      reaction: null,
      agent: "",
      focus: "",
      data_sources: [],
    },
    semantic_cache_answer: {
      answer: "",
      route_type: "",
    },
    preserved_selected_agents_focuses_data_sources: [],
    user_feedback: "1",
    submit_feedback_loader: false,
};

const reducer = (state: semanticCacheState = initialState, action: any): semanticCacheState => {
  switch (action.type) {
    case ActionType.SET_SEMANTIC_CACHE_DATA:
      return {...state, semantic_cache_data: action.payload};

    case ActionType.SET_SEMANTIC_CACHE_HAS_MORE:
      return {...state, has_more_cache: action.payload};

    case ActionType.SET_SEMANTIC_CACHE_LOADER:
      return {...state, is_semantic_cache_loader: action.payload};

    case ActionType.SET_COMMON_LOADER_FOR_SEMANTIC_CACHE:
      return {...state, is_common_loader_for_semantic_cache: action.payload};

    case ActionType.SET_IS_PRIVATE_ACTIVE:
      return {...state, is_private_active: action.payload};

    case ActionType.SET_IS_OPEN_EDIT_USER_FEEDBACK:
      return {...state, isOpenEditUserFeedback: action.payload};

    case ActionType.SET_SEMANTIC_AGENT_DATA:
      return {...state, agents: action.payload};

    case ActionType.SET_SEMANTIC_AGENT_HAS_MORE:
      return {...state, has_more_agents: action.payload};

    case ActionType.SET_SEMANTIC_FOCUS_DATA:
      return {...state, focuses: action.payload};

    case ActionType.SET_SEMANTIC_FOCUS_HAS_MORE:
      return {...state, has_more_focuses: action.payload};

    case ActionType.SET_SEMANTIC_SOURCE_DATA:
      return {...state, data_sources: action.payload};

    case ActionType.SET_SEMANTIC_SOURCE_HAS_MORE:
      return {...state, has_more_sources: action.payload};

    case ActionType.SET_SELECTED_AGENTS:
      return {...state, selected_agents: action.payload};

    case ActionType.SET_SELECTED_FOCUSES:
      return {...state, selected_focuses: action.payload};

    case ActionType.SET_SELECTED_DATA_SOURCES:
      return {...state, selected_data_sources: action.payload};

    case ActionType.RESET_AGENT_FOCUS_DATA_SOURCE:
      return {...state, agents: [], focuses: [], data_sources: []};

    case ActionType.RESET_SELECTED_AGENT_FOCUS_DATA_SOURCE:
      return {...state, selected_agents: [], selected_focuses: [], selected_data_sources: []};

    case ActionType.SET_SEMANTIC_CACHE_QUESTIONS_DATA:
      return {...state, semantic_cache_questions: action.payload};

    case ActionType.SET_SEMANTIC_CACHE_QUESTIONS_HAS_MORE:
      return {...state, has_more_semantic_cache_questions: action.payload};

    case ActionType.STORE_SEMANTIC_ANSWER_CACHE:
      return {...state, selected_cache_answer: action.payload};

    case ActionType.SET_SEMANTIC_CACHE_ANSWER:
      return {...state, semantic_cache_answer: action.payload};

    case ActionType.SET_PRESERVED_SELECTED_AGENTS_FOCUSES_DATA_SOURCES:
      return {...state, preserved_selected_agents_focuses_data_sources: action.payload};

    case ActionType.UPDATE_USER_FEEDBACK:
      return {...state, user_feedback: action.payload};

    case ActionType.SET_SUBMIT_FEEDBACK_LOADER:
      return {...state, submit_feedback_loader: action.payload};

    default:
      return state;
  }
};

export default reducer;
