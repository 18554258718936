/* The sendMessage function triggers the SendMessage Mutation, yet we don't use its response in the frontend.
  Instead, we establish a connection with the SubscribeSendMessage Subscription,
  which activates whenever we initiate the SendMessage Mutation.
  This approach ensures a seamless user experience across multiple screens simultaneously. */

export const sendMessage = `
  mutation sendMessage(
    $chat_id: String!
    $is_new_chat: Boolean
    $sources: [String]
    $focus: String
    $message_intent: MessageIntent!
    $user_message: UserMessageInput!
    $message_id: String
    $question_id: String
  ) {
    sendMessage(
      chat_id: $chat_id
      is_new_chat: $is_new_chat
      sources: $sources
      focus: $focus
      message_intent: $message_intent
      user_message: $user_message
      message_id: $message_id
      question_id: $question_id
    ) {
      event_type
      id
      created_at
      user_id
      event_metadata {
        event_message
        event_data {
          id
          question_id
          answer_id
          question_version_number
          is_visually_hidden
          created_at
          recepient
          message_metadata {
            focus
            sources
            processing_status {
              state
              reason
            }
            user_message {
              author {
                role
                tool
                metadata
              }
             content {
              type
              message
              metadata
             }
             metadata
            }
            message_intent
            answer_version_number
          }
          sub_message_metadata {
            id
            delta {
              delta_data_type
              delta_type
              delta
              created_at
              updated_at
            }
            processing_status {
              state
              reason
            }
            readout_title
          }
        }
      }
      author {
        role
        tool
        metadata
      }
      thread_metadata {
        id
        topic
        file_upload_count
        users {
          user_id
          name
          email
          chat_role
        }
        agent
        created_at
        access_scope
        is_pinned_chat
        is_closed_chat
        is_chat_processed
        metadata
      }
      content {
        message
        metadata
        type
        project{
          project{
            id
            topic
            created_at
            created_by{
              name
              email
              user_id
              chat_role
            }
            metadata
          }
          project_metadata{
            agent
            metadata
            attachments{
              id
              name
              mimetype
              thumbnail
              s3_file_id
              metadata
            }
          }
        }
      }
    }
  }
`;
